var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slot-tables-section" }, [
    _c("h2", { staticClass: "section-heading" }, [_vm._v("Sponsorpakete")]),
    _vm._v(" "),
    _vm.earlybird.active
      ? _c("p", [
          _vm._v(
            "Alle Preise mit Frühbucher-Bonus bis einschließlich " +
              _vm._s(_vm.earlybird["valid-date"]) +
              "."
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.packages.sponsor, function (item, key) {
        return _c("div", { key: key, staticClass: "col-md-12" }, [
          _c("div", { staticClass: "sponsor-package" }, [
            _c("div", { staticClass: "sponsor-package--header" }, [
              _c("div", { staticClass: "sponsor-package--name" }, [
                _vm._v(_vm._s(item.prices[_vm.priceGroup].description)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sponsor-package--info" }, [
                _vm._v(
                  "Wählen Sie Ihr Standard Paket für " +
                    _vm._s(item.participants) +
                    " Personen mit diversen Spezialpaketen"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sponsor-package--row" }, [
              _c("div", { staticClass: "order-table" }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "w-auto title-cell" },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                item.prices[_vm.priceGroup].description
                              ),
                            },
                          }),
                          _vm._v(" "),
                          item.info
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    trigger: "hover",
                                    options: { placement: "right-start" },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "tooltip" }, [
                                    _c("div", {
                                      staticClass: "tooltip-inner",
                                      domProps: {
                                        innerHTML: _vm._s(item.info),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "info-circle-icon",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "pl-20 pr-20" }, [
                        _c("span", { staticClass: "quantity" }, [
                          _vm._v(
                            "Verfügbar " + _vm._s(item.quantity) + " Stk."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "pl-20 pr-20" }, [
                        _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                item.prices[_vm.priceGroup].price
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("v-radio", {
                            attrs: {
                              id: `sponsor-package-${item.id}`,
                              name: "sponsor-package",
                            },
                            model: {
                              value: _vm.sponsorPackage,
                              callback: function ($$v) {
                                _vm.sponsorPackage = $$v
                              },
                              expression: "sponsorPackage",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("perfect-scrollbar", { class: { "ps-online": _vm.online } }, [
    _c(
      "div",
      {
        staticClass: "checkbox-table",
        staticStyle: { "padding-right": "1px" },
      },
      [
        _c("table", [
          _vm.headers
            ? _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (header, key) {
                    return _c("td", { key: key, class: header.class }, [
                      _vm._v(_vm._s(header.title)),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.internalItems, function (item) {
                return [
                  (_vm.online && item.online) || !_vm.online
                    ? _c("row", {
                        key: item.id,
                        attrs: {
                          data: item,
                          checked: item.checked,
                          disabled: item.disabled || _vm.disabled,
                        },
                        on: { change: _vm.updateSelected },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
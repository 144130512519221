<template>
  <section class="slot-booking-section bb-dark mb-30">
    <h2 class="section-heading">Messestand</h2>

    <div class="row">
      <div class="col-md-8">
        <img src="/images/booth-map.jpg" style="width:100%" alt="Messestande" @click="lightbox = true">
        <lightbox
            :visible="lightbox"
            imgs="/images/booth-map.jpg"
            @hide="() => this.lightbox = false"
          ></lightbox>
      </div>
      <div class="col-md-4" style="justify-content: flex-end; display: flex; flex-direction: column;">
        <b>Wählen Sie Ihren Messestand</b><br>
        <p>Auf der Messestandkarte finden Sie alle Plätze. Die Standbezeichnung inkl. Beschreibung plus Nummer finden Sie auf unserer unten angeführten Auswahlliste.</p>
        <button type="button" class="btn btn-blue-dark" @click="lightbox = true">Vergrößern</button>
      </div>
    </div>
    <div class="pb-5 pt-5"></div>
    <div class="row">
      <div class="col-md-12" ref="error" v-if="error">
          <infobox :icon="true" class="infobox--alert">
            <template v-slot:title>Fehler!</template>
            Sie müssen einen Messestand ausählen.
          </infobox>
      </div>
      <div class="col-md-12">
        <div class="form-group is-filled">
          <input type="text" placeholder="Suche nach Firma..." class="form-control" v-model="search">
        </div>
      </div>
      <div class="col-md-12">
        <checkbox-table  :headers="headers" :items="items" v-model="selectedPackages" :single="true"></checkbox-table>
      </div>
    </div>
  </section>
</template>

<script>
import Infobox from '../Infobox'
import Lightbox from 'vue-easy-lightbox'
import { mapFields } from 'vuex-map-fields'
import CheckboxTable from '../CheckboxTable'
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapBasketState,
} = createNamespacedHelpers('basket/sponsorpackage')

export default {
  components: {
    Infobox,
    Lightbox,
    CheckboxTable
  },
  props: {
    error: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      search: '',
      lightbox: false,
      selectedPackages: [],
      headers: [
        {class: 'w-50', title: 'NR.'},
        {class: 'w-auto', title: 'Standbeschreibung'},
        {class: 'w-auto', title: 'Firma'},
        {class: 'w-50', title: 'Gekauft'},
      ]
    }
  },
  created() {
    if (this.selectedExhibitionBooth) {
      this.selectedPackages.push(this.selectedExhibitionBooth);
    }
  },
  computed: {
    ...mapDataState(['packages']),
    ...mapBasketState({ sponsorPackage: 'selected' }),
    ...mapFields('basket/exhibitionBooth', {
      selectedExhibitionBooth: 'selected'
    }),
    items () {
      let items = this.packages.booths.map(item => ({
        checked: item.booked || (this.selectedExhibitionBooth !== null && this.selectedExhibitionBooth.id === item.id),
        disabled: item.booked || (this.sponsorPackage !== null && item.type !== this.sponsorPackage.type) || this.sponsorPackage === null,
        ...item,
      }))
      if (this.search.length) {
        items = items.filter(item => item.company.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      }
      return items;
    }
  },
  watch: {
    selectedPackages: function (val) {
      this.selectedExhibitionBooth = [...val].pop()
    },
    error: function (val) {
      if (val === true) {
        this.$nextTick(() => {
          let rect = this.$refs.error.getBoundingClientRect()
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          let top = rect.top + scrollTop - 215;
          window.scrollTo({ top, behavior: 'smooth' });
        })
      }
    }
  }
}
</script>

import { getField, updateField } from 'vuex-map-fields'

const exhibitionBooth = {
  namespaced: true
}

// STATE
exhibitionBooth.state = {
  selected: null
}

// MUTATIONS
exhibitionBooth.mutations = {
  updateField,
  clear(state) {
    state.selected = null
  },
}

// ACTIONS
exhibitionBooth.actions = {
  clear({ commit }) {
    commit('clear')
  },
}

// GETTERS
exhibitionBooth.getters = {
  getField,
  item: ({ selected }) => selected,
  tax: (state, getters) => getters.brutto - getters.netto,
  netto: () => 0,
  brutto: () => 0,
}

export default exhibitionBooth;

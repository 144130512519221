var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "thankyou text-center" }, [
      _c("h2", { staticClass: "section-heading" }, [
        _vm._v("\n    Vielen Dank für Ihre Bestellung!\n  "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    Ihre Buchung wurde erfolgreich zur Prüfung und Bearbeitung weitergeleitet."
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    Sie haben eine automatisch generierte Eingangsbestätigung per E-Mail erhalten.\n  "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
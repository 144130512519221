import { getField, updateField } from 'vuex-map-fields'

const symposium = {
  namespaced: true
}

// STATE
symposium.state = {
  added: [],
  selected: {
    symposium: null,
    slot: null,
    extra: null,
    theme: null,
  }
}

// MUTATIONS
symposium.mutations = {
  updateField,
  addSymposium(state, { symposium }) {
    state.added.push(symposium)
  },
  added(state, items) {
    state.added = items
  },
}

// ACTIONS
symposium.actions = {
  addSymposium({ commit, state }) {
    commit('addSymposium', { symposium: { ...state.selected } })
  },
  removeItem ({ state: { added }, commit }, id) {
    let items = [...added]
    let found = items.filter(i => i.symposium.code === id)
    if (found.length) {
      for (let index in found) {
        let item = found[index];
        items.splice(items.indexOf(item), 1)
      }
      commit('added', items)
    }
  },
}

// GETTERS
symposium.getters = {
  getField,
  items: (state, getters, rootState) => {
    let items = state.added.reduce((prev, el) => {
      let item = rootState.data.symposium
        .filter(i => i.id === el.symposium.code)
        .pop()

      prev.push({...el, symposium: { ...item, quantity: 1 } })

      return prev
    }, [])

    return items
      .map(item => {
        return {...item, total: item.symposium.price * item.symposium.quantity }
      })
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items.reduce((total, item) => total + item.total, 0)
  },
  brutto: (state, getters) => {
    return Math.round(
      getters.items.reduce((total, item) => total + (item.total * (1 + item.symposium.tax / 100)), 0) * 100
    ) / 100
  },
}

export default symposium;

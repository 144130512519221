<template>
  <div :class="groupClassList">
    <v-label :name="name" :label="label" :tooltip="tooltip"></v-label>
    <input type="text" class="form-control" v-model="internalValue" :name="name" :id="name" :tabindex="tabindex" :disabled="disabled">
    <v-error :message="errorMessage"></v-error>
  </div>
</template>

<script>
import { VLabel, VError } from '../Form'

export default {
  components: {
    VLabel,
    VError
  },
  props: {
    tabindex: {
      default: null,
      type: [Number]
    },
    value: {
      default: ''
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    error: {
      default: null,
      type: [Boolean]
    },
    errorMessage: {
      default: null,
      type: [String, Boolean]
    },
    tooltip: {
      default: null,
      type: [String]
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      lazyValue: this.value
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },
    groupClassList () {
      return {
        'form-group': true,
        'has-error': this.error,
        'is-filled': this.lazyValue && this.lazyValue.length
      }
    }
  },

  watch: {
    value (val) {
      this.lazyValue = val
    }
  },

}
</script>

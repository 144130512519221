import Form from './Form'
import Tooltip from './Tooltip.vue'
import Slotbox from './Slotbox.vue'
import Infobox from './Infobox.vue'
import Navigation from './Navigation.vue'
import OrderTable from './OrderTable'
import SimpleSpinner from './SimpleSpinner.vue'

export {
  Form,
  Tooltip,
  Slotbox,
  Infobox,
  Navigation,
  OrderTable,
  SimpleSpinner,
}

export default {
  Form,
  Tooltip,
  Slotbox,
  Infobox,
  Navigation,
  OrderTable,
  SimpleSpinner,
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.label
    ? _c(
        "label",
        { attrs: { for: _vm.name } },
        [
          _vm._v("\n  " + _vm._s(_vm.label) + "\n  "),
          _vm.tooltip
            ? _c(
                "tooltip",
                { attrs: { trigger: _vm.trigger, options: _vm.options } },
                [
                  _c("div", { staticClass: "tooltip" }, [
                    _c("div", {
                      staticClass: "tooltip-inner",
                      domProps: { innerHTML: _vm._s(_vm.tooltip) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-circle-icon",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { getField, updateField } from 'vuex-map-fields'

const specialpackage = {
  namespaced: true
}

// STATE
specialpackage.state = {
  selected: []
}

// MUTATIONS
specialpackage.mutations = {
  updateField,
  selected(state, items) {
    state.selected = items
  },
}

// ACTIONS
specialpackage.actions = {
  removeItem ({ state: { selected }, commit }, id) {
    var item = selected.filter(item => item.id === id).pop()
    if (item) {
      let items = [...selected];
      items.splice(selected.indexOf(item), 1);
      commit('selected', items)
    }
  },
}

// GETTERS
specialpackage.getters = {
  getField,
  items: ({ selected }, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';

    return selected.map(item => (
      {...item, total: item.prices[priceGroup].price * item.quantity }
    ))
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items.reduce((total, item) => total + item.total, 0)
  },
  brutto: (state, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';

    return Math.round(
      getters.items.reduce((total, item) => total + (item.total * (1 + item.prices[priceGroup].tax / 100)), 0) * 100
    ) / 100
  },
}

export default specialpackage;

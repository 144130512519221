var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "infobox", class: _vm.classList }, [
        _c("div", { staticClass: "infobox-title" }, [_vm._t("title")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "infobox-content" }, [_vm._t("default")], 2),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-table" }, [
    _c("table", [
      _c("thead", [
        _c("tr", [
          _c("td", { staticClass: "w-auto" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("td", { staticClass: "w-100" }, [_vm._v("Verfügbar.")]),
          _vm._v(" "),
          _c("td", { staticClass: "w-150" }, [_vm._v("PREISE")]),
          _vm._v(" "),
          _c("td", { staticClass: "w-50" }, [_vm._v("ANZAHL")]),
          _vm._v(" "),
          _c("td", { staticClass: "w-50" }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.items, function (item) {
          return _c("row", {
            key: item.id,
            attrs: { quantity: _vm.getQuantityFor(item.id), data: item },
            on: { change: _vm.updateSelected },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
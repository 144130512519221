var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.checkClassList },
    [
      _c("label", { staticClass: "checkbox-switch" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalValue,
              expression: "internalValue",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.internalValue)
              ? _vm._i(_vm.internalValue, null) > -1
              : _vm.internalValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.internalValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.internalValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.internalValue = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "slider" }),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "checkbox-label",
          on: {
            click: function ($event) {
              _vm.internalValue = !_vm.internalValue
            },
          },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
          _vm.tooltip
            ? _c(
                "tooltip",
                {
                  attrs: {
                    trigger: "hover",
                    options: { placement: "right-start" },
                  },
                },
                [
                  _c("div", { staticClass: "tooltip" }, [
                    _c("div", {
                      staticClass: "tooltip-inner",
                      domProps: { innerHTML: _vm._s(_vm.tooltip) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-circle-icon",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-error", { attrs: { message: _vm.errorMessage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
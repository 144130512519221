<template>
  <section class="slot-tables-section">
    <!--<h2 v-if="loggedIn" class="section-heading">Ihr Sponsorpaket</h2>-->
    <h2 class="section-heading">Sponsorpakete</h2>

    <p v-if="earlybird.active">Alle Preise mit Frühbucher-Bonus bis einschließlich {{ earlybird['valid-date'] }}.</p>
    <div class="row">
      <div class="col-md-12" v-for="(item, key) in packages.sponsor" :key="key">
        <div class="sponsor-package">
          <div class="sponsor-package--header">
            <div class="sponsor-package--name">{{ getItemDescription(item) }}</div>
            <div class="sponsor-package--info">
              Wählen Sie Ihr Standard Paket für {{ item.participants }} {{ item.participants > 1 ? 'Personen' : 'Person' }} mit diversen Spezialpaketen
              <div class="sponsor-package--size pull-right">
                Größe:
                <template v-for="(size, sizeKey) in item.sizeSelect">
                  <template v-if="size.id == item.size">{{ size.value }}</template>
                </template>
              </div>
            </div>
          </div>
          <div class="sponsor-package--row">
            <div class="order-table">
              <table>
                <tbody>
                  <tr>
                    <td class="w-auto title-cell">
                      <span v-html="getItemDescription(item)"></span>
                      <i v-if="item.info" class="mdi mdi-information mdi-24px" style="float: right;" @click="openModal(key)"></i>
                      <div class="modal" :class="{ 'is-active': activeModal === key }">
                        <div class="modal-background" @click="closeModal"></div>
                        <div class="modal-content">
                          <span v-html="item.info"></span>
                        </div>
                      </div>
                    </td>
                    <td class="pl-20 pr-20" v-if="item.quantity > 0"><span class="quantity">Verfügbar {{ item.quantity }} Stk.</span></td>
                    <td class="pl-20 pr-20" v-else><span class="quantity">Ausgebucht</span></td>
                    <td class="pl-20 pr-20"><span class="price">{{ getItemPrice(item) | currency }}</span></td>
                    <td class="text-center">
                      <v-radio :id="`sponsor-package-${item.id}`" name="sponsor-package" v-model="sponsorPackage" :disabled="loggedIn || item.quantity < 1"></v-radio>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import VRadio from '../Form/VRadio'
import Tooltip from '../Tooltip'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapUserState,
  mapGetters: mapUserGetters,
} = createNamespacedHelpers('basket/user')

const {
  mapActions: mapExhibitActions,
} = createNamespacedHelpers('basket/exhibitionBooth')

export default {
  components: {
    VRadio,
    Tooltip,
  },
  data() {
    return {
      sponsorPackage: null,
      activeModal: null, // Track the active modal
    }
  },
  created() {
    if (this.selectedSponsorPackage) {
      this.sponsorPackage = `sponsor-package-${this.selectedSponsorPackage.id}`
    }
  },
  methods: {
    ...mapExhibitActions({
      clearExhibit: 'clear'
    }),
    getItemDescription(item) {
      if (!item.prices) return '';
      const priceGroup = this.isMember ? 'member' : 'non-member';
      return item.prices[priceGroup]?.description || '';
    },
    getItemPrice(item) {
      if (!item.prices) return null;
      const priceGroup = this.isMember ? 'member' : 'non-member';
      return item.prices[priceGroup]?.price || null;
    },
    openModal(key) {
      this.activeModal = key;
    },
    closeModal() {
      this.activeModal = null;
    }
  },
  computed: {
    ...mapDataState(['packages', 'earlybird']),
    ...mapUserState(['company']),
    ...mapUserGetters(['isMember']),
    ...mapFields('basket/sponsorpackage', {
      selectedSponsorPackage: 'selected'
    }),
    ...mapFields('basket/user', {
      loggedIn: 'loggedIn',
    }),
    selected() {
      return this.sponsorPackage ? this.packages.sponsor.filter(
        item => item.id === parseInt(this.sponsorPackage.split('-').pop())
      ).pop() : null
    },
  },
  watch: {
    sponsorPackage: function (val) {
      this.selectedSponsorPackage = this.selected
      this.clearExhibit()
    }
  },
  filters: {
    currency(val) {
      return val == 0 ? "Auf Anfrage" : val?.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c("div", { staticClass: "checkout-cart" }, [
        _c("table", { staticClass: "text-medium" }, [
          _c("thead", [
            _c("tr", [
              _c(
                "td",
                { staticClass: "title w-auto", attrs: { colspan: "3" } },
                [
                  _vm._v("\n          SPONSORPAKET:\n          "),
                  _vm.tooltip
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            trigger: "hover",
                            options: { placement: "right-start" },
                          },
                        },
                        [
                          _c("div", { staticClass: "tooltip" }, [
                            _c("div", {
                              staticClass: "tooltip-inner",
                              domProps: { innerHTML: _vm._s(_vm.tooltip) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "info-circle-icon",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.editable ? _c("td", { staticClass: "w-50" }) : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("tbody", [
            _vm.details
              ? _c(
                  "tr",
                  {
                    staticClass: "w-auto pr-40 has-separators",
                    class: { "has-separators": _vm.editable },
                  },
                  [
                    _c("td", [
                      _vm._v(
                        "\n          " + _vm._s(_vm.description) + "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "w-50 text-center text-bold" }, [
                      _vm._v("1"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "w-150 text-right text-bold pl-20",
                        class: {
                          "text-right": !_vm.editable,
                          "text-center": _vm.editable,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("currency")(_vm.item.total)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.editable
                      ? _c("td", { staticClass: "pl-20" }, [
                          _c("span", {
                            staticClass: "trash-icon",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.clear()
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: _vm.editable ? 3 : 2 } },
                [
                  _vm.details
                    ? [
                        _vm._v(
                          "\n            Teilnehmer*in: " +
                            _vm._s(_vm.item.participants) +
                            " Personen\n            "
                        ),
                        _vm.exhibit
                          ? [
                              _vm._v(
                                "\n              | Messestand: " +
                                  _vm._s(_vm.exhibit.nr) +
                                  " - " +
                                  _vm._s(_vm.exhibit.description)
                              ),
                              _c("br"),
                            ]
                          : _vm.item.type == 4
                          ? [
                              _vm._v("\n              | Messestand: Online"),
                              _c("br"),
                            ]
                          : _vm.item.type != 1
                          ? [
                              _vm._v("\n              | Messestand: "),
                              _c("span", { staticClass: "text-red-light" }, [
                                _vm._v("kein Messestand ausgewählt"),
                              ]),
                              _c("br"),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v("\n          Summe Netto"),
                  _c("br"),
                  _vm._v("\n          20% MwSt.\n        "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _vm.details ? [_c("br")] : _vm._e(),
                  _vm._v(
                    "\n          " + _vm._s(_vm._f("currency")(_vm.netto))
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("currency")(_vm.tax)) +
                      "\n        "
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("tr", { staticClass: "text-blue-dark text-bold" }, [
              _c("td", { attrs: { colspan: _vm.editable ? 3 : 2 } }, [
                _vm._v("Summe Brutto"),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.brutto))),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="step-one">
    <section class="info-section" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>

    <participant-packages></participant-packages>

    <template v-if="(selectedParticipantPackage !== null && selectedParticipantPackage.length) || loggedIn">
      <section class="form-section">
        <h2 class="section-heading">
          <template v-if="onlineParticipants > 0">Online </template>
          Teilnehmer*in
        </h2>
        <div class="row">
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="1" name="company" label="Firmenname:" v-model="companyName" :error="error('company.name')" :error-message="error('company.name', true)"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="2" name="title" v-model="contactTitle" label="Titel:"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="3" name="vorname" label="Vorname: *" v-model="contactFirstname" :error="error('contact.firstname')" :error-message="error('contact.firstname', true)"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="4" name="nachname" v-model="contactLastname" label="Nachname: *" :error="error('contact.lastname')" :error-message="error('contact.lastname', true)"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="5" name="street" label="Straße: *" v-model="companyStreet" :error="error('company.street')" :error-message="error('company.street', true)"></v-input>
          </div>
          <div class="col-md-2">
            <v-input :disabled="loggedIn" :tabindex="6" name="zip" label="PLZ: *" v-model="companyZip" :error="error('company.zip')" :error-message="error('company.zip', true)"></v-input>
          </div>
          <div class="col-md-4">
            <v-input :disabled="loggedIn" :tabindex="7" name="city" label="Ort: *" v-model="companyCity" :error="error('company.city')" :error-message="error('company.city', true)"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="8" name="region" v-model="contactRegion" label="Bundesland:" :error="error('contact.region')" :error-message="error('contact.region', true)"></v-input>
          </div>
          <div class="col-md-6">
            <v-select :disabled="loggedIn" :tabindex="9" name="country" v-model="companyCountry" label="Land:" :options="countries" :error="error('company.country')" :error-message="error('company.country', true)"></v-select>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="10" name="phone" label="Telefon:" v-model="contactPhone"></v-input>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="11" name="mail" label="E-Mail: *" v-model="contactEmail" :error="error('contact.email')" :error-message="error('contact.email', true)"></v-input>
          </div>
        </div>
      </section>

      <section class="switch-section">
        <v-switch :disabled="loggedIn" v-model="useInvoiceAddress" name="useInvoiceAddress" label="abweichende Rechnungsadresse"></v-switch>
        <div class="extraText">Anderenfalls wird die Rechnung an die obenstehenden Daten ausgestellt</div>
      </section>

      <section v-if="useInvoiceAddress" class="form-section">
        <h2 class="section-heading">Rechnungsadresse:</h2>
        <div class="row">
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="9" name="company" v-model="invoiceName" label="Firmenname:" :error="error('invoice.name')" :error-message="error('invoice.name', true)"></v-input>
            <v-input :disabled="loggedIn" :tabindex="11" name="street" v-model="invoiceStreet" label="Straße: *" :error="error('invoice.street')" :error-message="error('invoice.street', true)"></v-input>
            <v-select :tabindex="13" name="country" v-model="invoiceCountry" label="Land:" :options="countries" :error="error('invoice.country')" :error-message="error('invoice.country', true)"></v-select>
          </div>
          <div class="col-md-6">
            <v-input :disabled="loggedIn" :tabindex="10" name="zip" v-model="invoiceZip" label="PLZ: *" :error="error('invoice.zip')" :error-message="error('invoice.zip', true)"></v-input>
            <v-input :disabled="loggedIn" :tabindex="12" name="city" v-model="invoiceCity" label="Ort: *" :error="error('invoice.city')" :error-message="error('invoice.city', true)"></v-input>
          </div>
        </div>
      </section>

      <section class="form-section" v-if="onlineParticipants == 0">
        <div class="row">
          <div class="col-md-6">
            <v-select :tabindex="12" name="eveningEvent" v-model="contactEveningEvent" label="Abendveranstaltung*" :options="[{label: 'Ja', code: 'true'},{label: 'Nein', code: 'false'}]" :error="error('contact.eveningEvent')" :error-message="error('contact.eveningEvent', true)"></v-select>
          </div>
        </div>
      </section>

      <section class="form-section">
        <p class="m-0">Programmpakete*</p>
        <div class="form-group has-error" v-if="error('contact.package')">
          <div class="invalid-tooltip">{{ error('contact.package', true) }}</div>
        </div>
        <checkbox-table class="mt-5" :items="items" :online="onlineParticipants > 0" :single="true" v-model="contactPackage"></checkbox-table>
      </section>

      <participants :max="maxParticipants" :online-participants="onlineParticipants" :form="form"></participants>

      <section class="summary-section mt-30 mb-30" v-if="selectedParticipantPackage !== null && selectedParticipantPackage.length">
        <h2 class="section-heading"><span class="shopping-cart-icon mr-10"></span>Warenkorb</h2>
        <participant-summary :details="true" :editable="true"></participant-summary>
      </section>

      <section class="button-section text-right">
        <button class="btn btn-blue-dark" @click.prevent="next">weiter</button>
      </section>
    </template>
  </section>
</template>

<script>
import { Infobox } from '../components'
import { VInput, VSelect, VSwitch } from '../components/Form'
import ParticipantPackages from '../components/Elements/ParticipantPackages'
import Participants from '../components/Elements/Participants'
import CheckboxTable from '../components/CheckboxTable'
import { ParticipantSummary } from '../components/Checkout'



import { mapFields } from 'vuex-map-fields'
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapBasketState,
  mapActions: mapBasketActions,
  mapGetters: mapBasketGetters,
} = createNamespacedHelpers('basket')

export default {
  components: {
    VInput,
    VSelect,
    VSwitch,
    Infobox,
    Participants,
    CheckboxTable,
    ParticipantSummary,
    ParticipantPackages,
  },

  data () {
    return {
      info: null,
      form: null
    }
  },

  computed: {
    ...mapDataState(['packages', 'countries']),
    ...mapBasketGetters(['validate']),
    ...mapFields('basket/participantpackage', {
      selectedParticipantPackage: 'selected'
    }),
    ...mapFields('basket/user', {
      loggedIn: 'loggedIn',
      useInvoiceAddress: 'useInvoiceAddress',

      companyUid: 'company.uid',
      companyZip: 'company.zip',
      companyName: 'company.name',
      companyCity: 'company.city',
      companyStreet: 'company.street',
      companyCountry: 'company.country',
      companySponsor: 'company.sponsor',
      companyContract: 'company.contract',
      companyMember: 'company.member',

      invoiceZip: 'invoice.zip',
      invoiceName: 'invoice.name',
      invoiceCity: 'invoice.city',
      invoiceStreet: 'invoice.street',
      invoiceCountry: 'invoice.country',

      contactSex: 'contact.sex',
      contactFax: 'contact.fax',
      contactPhone: 'contact.phone',
      contactTitle: 'contact.title',
      contactEmail: 'contact.email',
      contactMobile: 'contact.mobile',
      contactLastname: 'contact.lastname',
      contactFirstname: 'contact.firstname',
      contactPostnominal: 'contact.postnominal',

      contactRegion: 'contact.region',
      contactEveningEvent: 'contact.eveningEvent',
      contactPackageExternal: 'contact.package',
    }),

    onlineParticipants () {
      let qty = 0;
      if (this.selectedParticipantPackage && this.selectedParticipantPackage.length) {
        this.selectedParticipantPackage.forEach(pkg => {
          if (pkg.online) {
            qty += pkg.qty
          }
        });
      }
      return qty > 0 ? qty : 0;
    },

    maxParticipants () {
      let qty = 0;
      if (this.selectedParticipantPackage && this.selectedParticipantPackage.length) {
        this.selectedParticipantPackage.forEach(pkg => {
          qty += pkg.qty
        });
      }
      return qty > 0 ? qty - 1 : 0;
    },

    items () {
      let index = 0;
      let packages = this.packages.program.map(item => {
        index++;
        let checked = this.contactPackageExternal !== null && this.contactPackageExternal.id === item.id;
        return { nr: `0${index}`, checked, ...item }
      });
      return packages;
    },
    contactPackage: {
      get() {
        return this.contactPackageExternal ? [this.contactPackageExternal] : [];
      },
      set(val) {
        this.contactPackageExternal = val ? [...val].pop() : null
      }
    },
  },

  async created () {
    this.info = await this.infoForStep(1)
  },

  methods: {
    ...mapDataActions(['infoForStep']),

    error(field, returnMessage = false) {
      return this.form && this.form.errors.has(field)
        ? returnMessage && this.form.errors.get(field) || true
        : false
    },

    next() {
      // Regex for email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // Check if the email is valid
      if (!emailPattern.test(this.contactEmail)) {
        alert('Ungültige E-Mail-Adresse');
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.$router.push({ name: 'step-2' })
      }
    },
  },

  async beforeRouteLeave (to, from, next) {
    this.form = await this.validate('participant')
    if (this.form.errors.any() === false) {
      next()
    }
  }
}
</script>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app-root" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-10 offset-1" }, [_c("navigation")], 1),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-50" }, [
        _c("div", { staticClass: "col-10 offset-1" }, [
          _c("div", { staticClass: "white-container" }, [_c("router-view")], 1),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slot-tables-section" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.packages.participants, function (item, key) {
        return _c(
          "div",
          { key: key, staticClass: "col-md-12" },
          [
            _vm._l(item.prices, function (pkg, index) {
              return [
                _c(
                  "div",
                  { key: `${key}-${index}`, staticClass: "sponsor-package" },
                  [
                    _c("div", { staticClass: "sponsor-package--header" }, [
                      _c("div", { staticClass: "sponsor-package--name" }, [
                        _vm._v(_vm._s(pkg.description)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sponsor-package--info" },
                        [
                          _vm.earlybird.active
                            ? [
                                _vm._v(
                                  "\n                Preis ist gültig bis einschließlich " +
                                    _vm._s(_vm.earlybird["valid-date"]) +
                                    "\n              "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "sponsor-package--row" }, [
                      _c("div", { staticClass: "order-table" }, [
                        _c("table", [
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "w-auto title-cell" },
                                [
                                  _c("span", [_vm._v("Pro Person")]),
                                  _vm._v(" "),
                                  item.info
                                    ? _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            trigger: "hover",
                                            options: {
                                              placement: "right-start",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tooltip" },
                                            [
                                              _c("div", {
                                                staticClass: "tooltip-inner",
                                                domProps: {
                                                  innerHTML: _vm._s(item.info),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "info-circle-icon",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "pl-20 pr-20" }, [
                                _c("span", { staticClass: "quantity" }, [
                                  _vm._v(
                                    "Verfügbar " +
                                      _vm._s(item.quantity) +
                                      " Stk."
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "pl-20 pr-20" }, [
                                _c("span", { staticClass: "price" }, [
                                  _vm._v(_vm._s(_vm._f("currency")(pkg.price))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-center w-50" }, [
                                _c("span", { staticClass: "quantity" }, [
                                  _vm._v(_vm._s(_vm.getItemQuantity(pkg))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "p-none w-50" },
                                [
                                  item.quantity > 0
                                    ? _c("simple-spinner", {
                                        attrs: {
                                          max: item.quantity,
                                          value: _vm.getItemQuantity(pkg),
                                        },
                                        on: {
                                          input: (qty) =>
                                            _vm.handleItemQuantity(qty, pkg),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span class="input-spinner-group">
    <a class="spinner-button spinner-button-up" href="#" @click.prevent="increaseNumber">+</a>
    <a class="spinner-button spinner-button-down" href="#" @click.prevent="decreaseNumber">-</a>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 9999,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    }
  },
  data () {
    return {
      numericValue: this.value
    };
  },

  methods: {
    increaseNumber() {
      if (this.numericValue < this.max) {
        this.numericValue = Math.round((this.numericValue + this.step) * 100) / 100;
      }
    },
    decreaseNumber() {
      if (this.numericValue > this.min) {
        this.numericValue = Math.round((this.numericValue - this.step) * 100) / 100;
      }
    },
  },

  watch: {
    numericValue: function(val, oldVal) {
      if (val <= this.min) {
        this.numericValue = parseInt(this.min);
      }
      if (val >= this.max) {
        this.numericValue = parseInt(this.max);
      }
      if (val <= this.max && val >= this.min) {
        this.$emit('input', val, oldVal);
      }
    }
  },
}
</script>


<template>
  <div v-if="message" class="invalid-tooltip">
    {{ message }}
  </div>
</template>
<script>
export default {
  props: {
    message: {
      default: null,
      type: [String, Boolean]
    }
  }
}
</script>

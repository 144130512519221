import { getField, updateField } from 'vuex-map-fields'

const participants = {
  namespaced: true
}

// STATE
participants.state = {
  participants: []
}

// MUTATIONS
participants.mutations = {
  updateField,
}

// ACTIONS
participants.actions = {
}

// GETTERS
participants.getters = {
  getField,
}

export default participants;

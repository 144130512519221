import Vue  from 'vue'
import Vuex from 'vuex'
import createStorage from './plugins/local-storage'
import createModules from './modules'

Vue.use(Vuex);

export default class Store extends Vuex.Store {
  constructor(state) {

    const modules = createModules()

    super({
      strict: true,
      state,
      plugins: [
        createStorage('participant-22')
      ],
      modules
    });
  }
}

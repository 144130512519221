import { getField, updateField } from 'vuex-map-fields'

const participantpackage = {
  namespaced: true
}

// STATE
participantpackage.state = {
  selected: []
}

// MUTATIONS
participantpackage.mutations = {
  updateField,
  selected(state, items) {
    state.selected = items
  },
  clear(state) {
    state.selected = []
  },
}

// ACTIONS
participantpackage.actions = {
  removeItem ({ state: { selected }, commit }, id) {
    var item = selected.filter(item => item.id === id).pop()
    if (item) {
      let items = [...selected];
      items.splice(selected.indexOf(item), 1);
      commit('selected', items)
    }
  },
  clear({ commit }) {
    commit('clear')
  },
}

// GETTERS
participantpackage.getters = {
  getField,
  items: ({ selected }, getters, rootState, rootGetters) => {
    return selected && selected.length
      ? selected.map((item) => ({
        ...item,
        total: item.price * item.qty,
        brutto: (Math.round(item.price * (1 + item.tax / 100))) * item.qty
      }))
      : null
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items
      ? getters.items.reduce((carry, item) => carry + item.total, 0)
      : 0;
  },
  brutto: (state, getters, rootState, rootGetters) => {
    return getters.items
      ? getters.items.reduce((carry, item) => carry + item.brutto, 0)
      : 0
  },
  onlineParticipants: (state) => {
    let qty = 0;
    if (state.selected && state.selected.length) {
      state.selected.forEach(pkg => {
        if (pkg.online) {
          qty += pkg.qty
        }
      });
    }
    return qty > 0 ? qty : 0;
  },
}

export default participantpackage;

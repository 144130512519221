<template>
  <section class="slot-tables-section">
    <div class="row">
      <div class="col-md-12" v-for="(item, key) in packages.participants" :key="key">
        <template v-for="(pkg, index) in item.prices">
          <div class="sponsor-package" :key="`${key}-${index}`">
            <div class="sponsor-package--header">
              <div class="sponsor-package--name">{{ pkg.description }}</div>
              <div class="sponsor-package--info">
                <template v-if="earlybird.active">
                  Preis ist gültig bis einschließlich {{ earlybird['valid-date'] }}
                </template>
              </div>
            </div>
            <div class="sponsor-package--row">
              <div class="order-table">
                <table>
                  <tbody>
                    <tr>
                      <td class="w-auto title-cell">
                        <span>Pro Person</span>
                        <tooltip
                          v-if="item.info"
                          trigger="hover"
                          :options="{ placement: 'right-start' }">
                          <div class="tooltip">
                            <div class="tooltip-inner" v-html="item.info"></div>
                          </div>
                          <span class="info-circle-icon" slot="reference"></span>
                        </tooltip>
                      </td>
                      <td class="pl-20 pr-20"><span class="quantity">Verfügbar {{ item.quantity }} Stk.</span></td>
                      <td class="pl-20 pr-20"><span class="price">{{ pkg.price | currency }}</span></td>
                      <td class="text-center w-50"><span class="quantity">{{ getItemQuantity(pkg) }}</span></td>
                      <td class="p-none w-50">
                        <simple-spinner v-if="item.quantity > 0" @input="(qty) => handleItemQuantity(qty, pkg)" :max="item.quantity" :value="getItemQuantity(pkg)"></simple-spinner>
                        <!-- <v-radio :id="`sponsor-package-${pkg.id}`" name="participant-package" v-model="participantPackage"></v-radio> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import VRadio from '../Form/VRadio'
import Tooltip from '../Tooltip'
import SimpleSpinner from '../SimpleSpinner'
import clone from 'lodash/cloneDeep'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapUserState,
  mapGetters: mapUserGetters,
} = createNamespacedHelpers('basket/user')

export default {
  components: {
    VRadio,
    Tooltip,
    SimpleSpinner,
  },
  data() {
    return {
      participantPackages: []
    }
  },
  created() {
    if (this.selectedParticipantPackages) {
      this.participantPackages = clone(this.selectedParticipantPackages)
    }
  },
  methods: {
    getItemQuantity(item) {
      const pkg = this.participantPackages.filter((pkg) => pkg.id === item.id).pop();
      return pkg ? pkg.qty : 0;
    },
    handleItemQuantity(qty, item) {
      const pkg = this.participantPackages.filter((pkg) => pkg.id === item.id).pop();

      if (pkg) {
        const index = this.participantPackages.indexOf(pkg);
        if (qty > 0) {
          this.participantPackages[index].qty = qty;
        } else {
          this.participantPackages.splice(index, 1);
        }
      } else {
        this.participantPackages.push({ qty, ...item })
      }
    }
  },
  computed: {
    ...mapDataState(['packages', 'earlybird']),
    ...mapUserState(['company']),
    ...mapUserGetters(['isMember']),
    ...mapFields('basket/participantpackage', {
      selectedParticipantPackages: 'selected'
    }),
    priceGroup() {
      return this.isMember ? 'member' : 'non-member';
    }
  },
  watch: {
    participantPackages: {
      deep: true,
      handler: function (val) {
        this.selectedParticipantPackages = clone(this.participantPackages)
      }
    }
  },
  filters: {
    currency(val) {
      return val == 0 ? "Auf Anfrage" : val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

<template>
  <tr :class="classList" :style="{ 'background-color': data.product_in_shop === 0 ? '#f5c4c4' : '' }">
    <td class="text-center"><b>{{ data.nr }}</b></td>
    <td class="title-cell">
      <span v-html="data.description"></span>
      <i v-if="data.product_description" class="mdi mdi-information mdi-24px" style="float: right;" @click="openModal"></i>
      <tooltip
        v-if="data.info"
        trigger="hover"
        :options="{ placement: 'right-start' }">
        <div class="tooltip">
          <div class="tooltip-inner" v-html="data.info"></div>
        </div>
        <span class="info-circle-icon" slot="reference"></span>
      </tooltip>
    </td>
    <td class="company-cell" v-if="data.company !== undefined">{{ data.company || '&nbsp;' }}</td>
    <td class="text-center">
      <v-checkbox name="" v-model="internalChecked" :disabled="disabled || data.product_in_shop === 0"></v-checkbox>
    </td>
    <div class="modal" :class="{ 'is-active': isModalOpen }">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <span v-html="data.product_description"></span>
      </div>
    </div>
  </tr>
</template>

<script>
import Tooltip from '../Tooltip'
import VCheckbox from '../Form/VCheckbox'

export default {
  components: {
    Tooltip,
    VCheckbox
  },

  props: {
    data: {
      default: null,
      type: [Object]
    },
    checked: {
      default: false,
      type: [Boolean]
    },
    disabled: {
      default: false,
      type: [Boolean]
    },
  },

  data () {
    return {
      lazyChecked: this.checked,
      isModalOpen: false
    }
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },

  computed: {
    classList () {
      return {
        active: this.lazyChecked,
        disabled: this.disabled,
        booked: this.data.booked,
      }
    },
    internalChecked: {
      get () {
        return this.lazyChecked
      },
      set (val) {
        this.lazyChecked = val;
        this.$emit('input', val);
        this.$emit('change', {...this.data, checked: val});
      }
    },
  },

  watch: {
    quantity: function (val) {
      this.lazyQty = val
    },
    checked: function (val) {
      this.lazyChecked = val
    }
  },
}
</script>

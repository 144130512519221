<template>
  <section class="slot-tables-section">
    <h2 class="section-heading">Sponsorpakete</h2>
    <p v-if="earlybird.active">Alle Preise mit Frühbucher-Bonus bis einschließlich {{ earlybird['valid-date'] }}.</p>
    <div class="row">
      <div class="col-md-12" v-for="(item, key) in packages.sponsor" :key="key">
        <div class="sponsor-package">
          <div class="sponsor-package--header">
            <div class="sponsor-package--name">{{ item.prices[priceGroup].description }}</div>
            <div class="sponsor-package--info">Wählen Sie Ihr Standard Paket für {{ item.participants }} Personen mit diversen Spezialpaketen</div>
          </div>
          <div class="sponsor-package--row">
            <div class="order-table">
              <table>
                <tbody>
                  <tr>
                    <td class="w-auto title-cell">
                      <span v-html="item.prices[priceGroup].description"></span>
                      <tooltip
                        v-if="item.info"
                        trigger="hover"
                        :options="{ placement: 'right-start' }">
                        <div class="tooltip">
                          <div class="tooltip-inner" v-html="item.info"></div>
                        </div>
                        <span class="info-circle-icon" slot="reference"></span>
                      </tooltip>
                    </td>
                    <td class="pl-20 pr-20"><span class="quantity">Verfügbar {{ item.quantity }} Stk.</span></td>
                    <td class="pl-20 pr-20"><span class="price">{{ item.prices[priceGroup].price | currency }}</span></td>
                    <td class="text-center">
                      <v-radio :id="`sponsor-package-${item.id}`" name="sponsor-package" v-model="sponsorPackage"></v-radio>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import VRadio from '../Form/VRadio'
import Tooltip from '../Tooltip'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapUserState,
  mapGetters: mapUserGetters,
} = createNamespacedHelpers('basket/user')

const {
  mapActions: mapExhibitActions,
} = createNamespacedHelpers('basket/exhibitionBooth')

export default {
  components: {
    VRadio,
    Tooltip,
  },
  data() {
    return {
      sponsorPackage: null
    }
  },
  created() {
    if (this.selectedSponsorPackage) {
      this.sponsorPackage = `sponsor-package-${this.selectedSponsorPackage.id}`
    }
  },
  methods: mapExhibitActions({
    clearExhibit: 'clear'
  }),
  computed: {
    ...mapDataState(['packages', 'earlybird']),
    ...mapUserState(['company']),
    ...mapUserGetters(['isMember']),
    ...mapFields('basket/sponsorpackage', {
      selectedSponsorPackage: 'selected'
    }),
    selected() {
      return this.sponsorPackage ? this.packages.sponsor.filter(
        item => item.id === parseInt(this.sponsorPackage.split('-').pop())
      ).pop() : null
    },
    priceGroup() {
      return this.isMember ? 'member' : 'non-member';
    }
  },
  watch: {
    sponsorPackage: function (val) {
      this.selectedSponsorPackage = this.selected
      this.clearExhibit()
    }
  },
  filters: {
    currency(val) {
      return val == 0 ? "Auf Anfrage" : val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

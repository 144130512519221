var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "participants" },
    [
      _vm._l(_vm.internalParticipants, function (participant, index) {
        return _c(
          "div",
          { key: index, staticClass: "participant--wrapper mt-4" },
          [
            _c(
              "h2",
              { staticClass: "section-heading" },
              [
                _vm.isOnline(index) ? [_vm._v("Online")] : _vm._e(),
                _vm._v(
                  "\n      Teilnehmer*in " + _vm._s(index + 2) + "\n      "
                ),
                _c(
                  "small",
                  {
                    staticClass: "pull-right text-blue-dark",
                    staticStyle: { "font-weight": "bold", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.removeParticipant(index)
                      },
                    },
                  },
                  [_vm._v("x")]
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("participant", {
              attrs: {
                online: _vm.isOnline(index),
                index: index,
                data: participant,
                form: _vm.form,
              },
              model: {
                value: _vm.internalParticipants[index],
                callback: function ($$v) {
                  _vm.$set(_vm.internalParticipants, index, $$v)
                },
                expression: "internalParticipants[index]",
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bt-blue bb-blue pt-1 pb-3 clearfix mb-5" }, [
        this.internalParticipants.length < this.max
          ? _c(
              "button",
              {
                staticClass: "btn btn-blue-dark pull-right",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addParticipant.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Weitere Teilnehmer*in")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "m-0" }, [
          _vm._v(
            "Aufgrund Ihrer Auswahl können Sie bis zu " +
              _vm._s(this.max + 1) +
              " Teilnehmer*in anmelden."
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="slot-booking-section bb-dark mb-30">

    <h2 v-if="loggedIn" class="section-heading">
      <template v-if="selectedExhibitionBooth">
        Ihr Messestand: {{ selectedExhibitionBooth.nr }} - {{ selectedExhibitionBooth.description }}
      </template>
      <template v-else>
        Kein Messestand.
      </template>
    </h2>
    <h2 v-else class="section-heading">Messestand</h2>

    <div class="row">
      <div class="col-md-8">
        <img :src="'https://my.1tool.com/'+mapImage" style="width:100%" alt="Messestande" @click="lightbox = true">
        <lightbox
            :visible="lightbox"
            :imgs="'https://my.1tool.com/'+mapImage"
            @hide="() => this.lightbox = false"
          ></lightbox>
      </div>
      <div class="col-md-4" style="justify-content: flex-end; display: flex; flex-direction: column;">
        <b>Wählen Sie Ihren Messestand</b><br>
        <p>Auf der Messestandkarte finden Sie alle Plätze. Die Standbezeichnung inkl. Beschreibung plus Nummer finden Sie auf unserer unten angeführten Auswahlliste.</p>
        <button type="button" class="btn btn-blue-dark" @click="lightbox = true">Vergrößern</button>
      </div>
    </div>
    <div class="pb-5 pt-5"></div>
    <div class="row" v-if="!loggedIn">
      <div class="col-md-12" ref="error" v-if="error">
          <infobox :icon="true" class="infobox--alert">
            <template v-slot:title>Fehler!</template>
            Sie müssen einen Messestand ausählen.
          </infobox>
      </div>
      <div class="col-md-12">
        <div class="form-group is-filled">
          <input type="text" placeholder="Suche nach Firma..." class="form-control" v-model="search">
        </div>
      </div>
      <div class="col-md-12">
        <checkbox-table  :headers="headers" :items="items" v-model="selectedPackages" :single="true"></checkbox-table>
      </div>
    </div>
    <div v-else>
      <div class="col-md-12">
        <checkbox-table  :headers="headers" :items="items" v-model="selectedPackages" :single="true"></checkbox-table>
      </div>
    </div>
  </section>
</template>

<script>
import Infobox from '../Infobox'
import Lightbox from 'vue-easy-lightbox'
import { mapFields } from 'vuex-map-fields'
import CheckboxTable from '../CheckboxTable'
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapDataState } = createNamespacedHelpers('data')
const { mapState: mapBasketState, mapGetters, mapActions } = createNamespacedHelpers('basket/sponsorpackage')
const { mapGetters: mapExhibitGetters, mapActions: mapExhibitActions, mapMutations: mapExhibitMutations } = createNamespacedHelpers('basket/exhibitionBooth')
const { mapGetters: mapUserGetters } = createNamespacedHelpers('basket/user')

export default {
  components: {
    Infobox,
    Lightbox,
    CheckboxTable
  },
  props: {
    error: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      search: '',
      lightbox: false,
      selectedPackages: [],
      headers: [
        { class: 'w-50', title: 'NR.' },
        { class: 'w-auto', title: 'Standbeschreibung' },
        { class: 'w-auto', title: 'Firma' },
        { class: 'w-50', title: 'Gekauft' },
      ]
    }
  },
  created() {
    if (this.selectedExhibitionBooth) {
      this.selectedPackages.push(this.selectedExhibitionBooth);
      this.updateField({ path: 'selected', value: this.selectedExhibitionBooth });
    }
  },
  methods: {
    ...mapExhibitActions(['clear']),
    ...mapExhibitMutations(['updateField']),
    clear() {
      this.updateField({ path: 'selected', value: null });
    },
    selectPackage(selectedPackage) {
      this.selectedPackages.push(selectedPackage);
    },
    unselectPackage(selectedPackage) {
      this.selectedPackages = this.selectedPackages.filter(p => p.id !== selectedPackage.id);
    }
  },
  computed: {
    ...mapDataState(['packages']),
    ...mapDataState(['map']),
    ...mapBasketState({ sponsorPackage: 'selected' }),
    ...mapFields('basket/exhibitionBooth', {
      selectedExhibitionBooth: 'selected'
    }),
    ...mapFields('basket/user', {
      loggedIn: 'loggedIn',
    }),
    ...mapGetters(['item']),
    ...mapUserGetters({
      userIsMember: 'isMember'
    }),
    ...mapExhibitGetters({
      exhibit: 'item'
    }),
    items() {
      let items = this.packages.booths.map(item => ({
        checked: item.booked || !!this.selectedPackages.find((selectedPackage) => selectedPackage.id == item.id) || (this.selectedExhibitionBooth !== null && this.selectedExhibitionBooth.id === item.id),
        disabled: item.booked || !this.description.includes(item.description),
        booked: item.booked,
        ...item,
      }));
      if (this.search.length) {
        items = items.filter(item => item.company && item.company.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
      }
      return items;
    },
    mapImage() {
      return this.map && this.map.image ? this.map.image : null;
    },
    description() {
      if (!this.item || !this.item.prices) {
        return '';
      }
      return this.userIsMember
        ? this.item.prices['member'].description
        : this.item.prices['non-member'].description;
    }
  },
  watch: {
    selectedPackages(val) {
      this.updateField({ path: 'selected', value: this.selectedPackages[0] });
      let selectedItem = [...val].pop();
      if (selectedItem && selectedItem.checked) {
        if (this.description.includes("Premium")) {
          if (selectedItem.description == "Premium") {
            this.selectedExhibitionBooth = selectedItem;
          }
        } else if (this.description.includes("Medium")) {
          if (selectedItem.description == "Medium") {
            this.selectedExhibitionBooth = selectedItem;
          }
        } else {
          this.clear();
        }
      }
    },
    error(val) {
      if (val === true) {
        this.$nextTick(() => {
          let rect = this.$refs.error.getBoundingClientRect();
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          let top = rect.top + scrollTop - 215;
          window.scrollTo({ top, behavior: 'smooth' });
        });
      }
    },
  },
};
</script>


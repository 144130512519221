var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slot-tables-section" }, [
    _c("h2", { staticClass: "section-heading" }, [_vm._v("Sponsorpakete")]),
    _vm._v(" "),
    _vm.earlybird.active
      ? _c("p", [
          _vm._v(
            "Alle Preise mit Frühbucher-Bonus bis einschließlich " +
              _vm._s(_vm.earlybird["valid-date"]) +
              "."
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.packages.sponsor, function (item, key) {
        return _c("div", { key: key, staticClass: "col-md-12" }, [
          _c("div", { staticClass: "sponsor-package" }, [
            _c("div", { staticClass: "sponsor-package--header" }, [
              _c("div", { staticClass: "sponsor-package--name" }, [
                _vm._v(_vm._s(_vm.getItemDescription(item))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sponsor-package--info" }, [
                _vm._v(
                  "\n            Wählen Sie Ihr Standard Paket für " +
                    _vm._s(item.participants) +
                    " " +
                    _vm._s(item.participants > 1 ? "Personen" : "Person") +
                    " mit diversen Spezialpaketen\n            "
                ),
                _c(
                  "div",
                  { staticClass: "sponsor-package--size pull-right" },
                  [
                    _vm._v("\n              Größe:\n              "),
                    _vm._l(item.sizeSelect, function (size, sizeKey) {
                      return [
                        size.id == item.size
                          ? [_vm._v(_vm._s(size.value))]
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sponsor-package--row" }, [
              _c("div", { staticClass: "order-table" }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "w-auto title-cell" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getItemDescription(item)),
                          },
                        }),
                        _vm._v(" "),
                        item.info
                          ? _c("i", {
                              staticClass: "mdi mdi-information mdi-24px",
                              staticStyle: { float: "right" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(key)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal",
                            class: { "is-active": _vm.activeModal === key },
                          },
                          [
                            _c("div", {
                              staticClass: "modal-background",
                              on: { click: _vm.closeModal },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "modal-content" }, [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(item.info) },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      item.quantity > 0
                        ? _c("td", { staticClass: "pl-20 pr-20" }, [
                            _c("span", { staticClass: "quantity" }, [
                              _vm._v(
                                "Verfügbar " + _vm._s(item.quantity) + " Stk."
                              ),
                            ]),
                          ])
                        : _c("td", { staticClass: "pl-20 pr-20" }, [
                            _c("span", { staticClass: "quantity" }, [
                              _vm._v("Ausgebucht"),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "pl-20 pr-20" }, [
                        _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.getItemPrice(item)))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("v-radio", {
                            attrs: {
                              id: `sponsor-package-${item.id}`,
                              name: "sponsor-package",
                              disabled: _vm.loggedIn || item.quantity < 1,
                            },
                            model: {
                              value: _vm.sponsorPackage,
                              callback: function ($$v) {
                                _vm.sponsorPackage = $$v
                              },
                              expression: "sponsorPackage",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <tr :class="classList">
    <td>
      <span v-html="data.description"></span>
      <tooltip
        v-if="data.info"
        trigger="hover"
        :options="{ placement: 'right-start' }">
        <div class="tooltip">
          <div class="tooltip-inner" v-html="data.info"></div>
        </div>
        <span class="info-circle-icon" slot="reference"></span>
      </tooltip>
      <small v-if="data.quantity <= 0" class="text-blue-dark">Ausgebucht</small>
      <i v-if="data.product_description" class="mdi mdi-information mdi-24px" style="float: right;" @click="openModal"></i>
    </td>
    <td class="pl-20 pr-20"><span class="quantity">Stk. {{ Math.max(0, data.quantity) }}</span></td>
    <td class="pl-20 pr-20"><span class="price">{{ data.price | currency }}</span></td>
    <td class="text-center"><span class="quantity">{{ qty }}</span></td>
    <td class="p-none">
      <simple-spinner v-if="data.quantity > 0" v-model="qty" :max="data.quantity"></simple-spinner>
    </td>
    <div class="modal" :class="{ 'is-active': isModalOpen }">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <span v-html="data.product_description"></span>
      </div>
    </div>
  </tr>
</template>

<script>
import Tooltip from '../Tooltip'
import SimpleSpinner from '../SimpleSpinner'

export default {
  components: {
    Tooltip,
    SimpleSpinner
  },

  props: {
    data: {
      default: null,
      type: [Object]
    },
    quantity: {
      default: 0,
      type: [Number]
    },
  },

  data () {
    return {
      lazyQty: this.quantity,
      isModalOpen: false
    }
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },

  computed: {
    classList () {
      return {
        active: this.qty > 0
      }
    },
    qty: {
      get () {
        return this.lazyQty
      },
      set (val) {
        this.lazyQty = val;
        this.$emit('input', val);
        this.$emit('change', {...this.data, quantity: val});
      }
    },
  },

  watch: {
    quantity: function (val) {
      this.lazyQty = val
    }
  },

  filters: {
    currency(val) {
      return val == 0 ? "Auf Anfrage" : val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

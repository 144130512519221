<template>
  <section class="thankyou text-center">
    <h2 class="section-heading">
      Vielen Dank für Ihre Bestellung!
    </h2>
    <p>
      Ihre Buchung wurde erfolgreich zur Prüfung und Bearbeitung weitergeleitet.<br>
      <br>
      Sie haben eine automatisch generierte Eingangsbestätigung per E-Mail erhalten.
    </p>
  </section>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => window.scrollTo({ top: 250, behavior: 'smooth' }), 100);
  }
}
</script>

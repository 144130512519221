var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items && _vm.items.length
    ? _c("div", { staticClass: "checkout-cart" }, [
        _c("table", { staticClass: "text-bold" }, [
          _c("thead", [
            _c("tr", [
              _c(
                "td",
                { staticClass: "title w-auto" },
                [
                  _vm._v("\n          IHRE SPEZIALPAKETE:\n          "),
                  _vm.tooltip
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            trigger: "hover",
                            options: { placement: "right-start" },
                          },
                        },
                        [
                          _c("div", { staticClass: "tooltip" }, [
                            _c("div", {
                              staticClass: "tooltip-inner",
                              domProps: { innerHTML: _vm._s(_vm.tooltip) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "info-circle-icon",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "w-50" },
                [_vm.headers ? [_vm._v("Menge")] : _vm._e()],
                2
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass: "w-150 text-right",
                  class: { "pr-20": _vm.editable },
                },
                [_vm.headers ? [_vm._v("Preis")] : _vm._e()],
                2
              ),
              _vm._v(" "),
              _vm.editable ? _c("td", { staticClass: "w-50" }) : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm.details
                ? _vm._l(_vm.items, function (item, key) {
                    return _c(
                      "tr",
                      { key: key, staticClass: "has-separators" },
                      [
                        _c("td", { staticClass: "w-auto pr-40" }, [
                          _c("b", [_vm._v(_vm._s(item.description))]),
                          _c("br"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "w-50 text-center text-bold" },
                          [_vm._v(_vm._s(item.quantity))]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "w-150 text-right text-bold pl-20",
                            class: { "pr-20": _vm.editable },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(item.prices["member"].price)
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.editable
                          ? _c("td", { staticClass: "pl-20" }, [
                              _c("span", {
                                staticClass: "trash-icon",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeItem(item.id)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.totals
                ? _c("tr", [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        attrs: { colspan: _vm.maxCols },
                      },
                      [
                        _vm._v(_vm._s(_vm._f("currency")(_vm.netto))),
                        _c("br"),
                        _vm._v(_vm._s(_vm._f("currency")(_vm.tax))),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.totals
                ? _c("tr", { staticClass: "text-blue-dark" }, [
                    _c("td", [_vm._v("Summe Brutto")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        attrs: { colspan: _vm.maxCols },
                      },
                      [_vm._v(_vm._s(_vm._f("currency")(_vm.brutto)))]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_vm._v("Summe Netto"), _c("br"), _vm._v("20% MwSt.")])
  },
]
render._withStripped = true

export { render, staticRenderFns }
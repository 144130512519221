const data = {
  namespaced: true
}

// STATE
data.state = {
  info: {},
  packages: {},
  countries: [],
  earlybird: {},
}

// MUTATIONS
data.mutations = {
  updateData(state, { module, data }) {
    state[module] = data
  },
}

// ACTIONS
data.actions = {
  infoForStep({ state }, step) {
    return state.info[`step-${step}`]
  },
  infoForTag({ state }, tag) {
    return state.info[tag]
  },
  updateData({ commit }, { module, data }) {
    commit('updateData', { module, data })
  }
}

// GETTERS
data.getters = {
}

export default {
  fresh: () => data,
  hydrated: () => {
    data.state = typeof INITIAL_STATE === 'undefined' ? {} : {
      ...INITIAL_STATE
    }
    return data;
  },
}

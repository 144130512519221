<template>
  <label v-if="label" :for="name">
    {{ label }}
    <tooltip
      v-if="tooltip"
      :trigger="trigger"
      :options="options">
      <div class="tooltip">
        <div class="tooltip-inner" v-html="tooltip"></div>
      </div>
      <span class="info-circle-icon" slot="reference"></span>
    </tooltip>
  </label>
</template>
<script>
import Tooltip from '../Tooltip'

export default {
  components: {
    Tooltip
  },

  props: {
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    tooltip: {
      default: null,
      type: [String]
    },
    trigger: {
      default: 'hover',
      type: [String]
    },
    options: {
      default () {
        return {
          placement: 'right-start'
        }
      },
      type: [Object]
    }
  },

}
</script>

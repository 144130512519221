import includes from 'array-includes'

export const storageTime = 24;

export const allowedModules = [
  'basket'
]

export const isAvailable = () => {
    let test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export const isOutdated = (storageKey) => {
  let now = new Date().getTime()
  let date = localStorage.getItem(`app-${storageKey || 'temp-22'}-date`) || now
  let delta = (now - parseInt(date)) / 1000 / 60 / 60

  // true if it is older than storageTime (a day)
  return delta >= storageTime
}

export const getModuleState = (module, defaultState, storageKey) => {
  try {
    let data = localStorage.getItem(`app-${storageKey || 'temp-22'}-data`)
    let state = JSON.parse(
      decodeURIComponent(escape(atob(data)))
    )
    return state[module]
      ? state[module]
      : defaultState
  } catch (e) {
    return defaultState
  }
}

export const update = (state, storageKey) => {
  try {
    let data =  Object.keys(state)
      .filter(key => includes(allowedModules, key))
      .reduce((obj, key) => {
        obj[key] = state[key]
        return obj
      }, {})

    localStorage.setItem(`app-${storageKey || 'temp-22'}-date`, new Date().getTime())
    localStorage.setItem(`app-${storageKey || 'temp-22'}-data`, btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify(data)
        )
      )
    ))
  } catch (e) {
  }
}

export const clear = (storageKey) => {
    localStorage.removeItem(`app-${storageKey || 'temp'}-date`)
    localStorage.removeItem(`app-${storageKey || 'temp'}-data`)
}


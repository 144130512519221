import { getField, updateField } from 'vuex-map-fields'
import { getModuleState, clear as clearStorage } from '@Utils/local-storage'
import includes from 'array-includes'
import clone from 'lodash/cloneDeep'
import modules from './modules'
import Form from '@Utils/Form'
import to from '@Utils/to'

const basket = {
  namespaced: true,
  modules
}

// STATE
basket.state = {
  loading: false,
  response: null,
}

// MUTATIONS
basket.mutations = {
  updateField,
  loading(state, value) {
    state.loading = value
  },
  response(state, { response }) {
    state.response = response
  }
}

// ACTIONS
basket.actions = {
  async submit({ commit, getters: { data } }) {
    commit('loading', true)
    const form = new Form(data)

    let [
      error,
      status
    ] = await to(
      form.post('/store')
    )

    commit('loading', false)
    commit('response', { response: form })

    if (error) {
      console.info('[Submit Failed]', error)
      return false
    }

    if (form.errors.any() === false && status.success) {
      clearStorage('participant-22')
      window.location = '/thankyou'
    }
  }
}

// GETTERS
basket.getters = {
  getField,
  brutto: (state, getters) =>
    getters['participantpackage/brutto'],
  data: (state, getters) => clone({
    ...state.user,
    agreements: state.agreements,
    participants: state.participants.participants,
    participantpackage: getters['participantpackage/items'],
    onlineParticipants: getters['participantpackage/onlineParticipants'],
  }),
  form: (state, getters) => module => {
    const data = {
      ...state[module],
      agreements: state.agreements,
      participants: state.participants.participants,
      participantpackage: getters['participantpackage/items'],
      onlineParticipants: getters['participantpackage/onlineParticipants'],
    };

    if (data.onlineParticipants > 0) {
      delete data['contact']['eveningEvent'];
    }

    if (data.onlineParticipants > 1) {
      for (let index = 0; index < data.onlineParticipants - 1 ; index++) {
        delete data.participants[index]['eveningEvent'];
      }
    }

    return new Form(data);
  },
  validate: (state, getters) => async module => {
    const stateModule = module === 'participant' ? 'user' : module;
    const form = getters.form(stateModule)

    let [
      error,
      status
    ] = await to(
      form.post(`/validate/${module}`)
    )

    error && console.info('[Validation Failed]', error)

    return form
  }
}

export default {
  fresh: () => basket,
  hydrated: () => {
    const state = getModuleState('basket', basket.state, 'participant-22')

    // Fetch module data
    let data =  Object.keys(state)
      .filter(key => includes(Object.keys(modules), key))
      .reduce((obj, key) => {
        obj[key] = state[key]
        return obj
      }, {})

    // Hydrate modules
    for (const key in basket.modules) {
      if (basket.modules.hasOwnProperty(key)) {
        if (data[key]) {
          basket.modules[key].state = data[key]
        }
      }
    }

    if (typeof USER_STATE !== 'undefined') {
      basket.modules.user.state.loggedIn = true
      basket.modules.user.state.contact = {
        ...basket.modules.user.state.contact,
        ...USER_STATE.contact
      }
      if (USER_STATE.company) {
        basket.modules.user.state.hasCompany = true
        basket.modules.user.state.company = {
          ...basket.modules.user.state.company,
          ...USER_STATE.company
        }
      }

      basket.modules.user.state.useInvoiceAddress = USER_STATE.useInvoiceAddress
      if (USER_STATE.useInvoiceAddress) {
        basket.modules.user.state.invoice = {
          ...basket.modules.user.state.invoice,
          ...USER_STATE.invoice
        }
      }

      basket.modules.participants.state.participants = USER_STATE.participants || []
      basket.modules.participantpackage.state.selected = USER_STATE.participantPackages || []
    } else {
      basket.modules.user.state.loggedIn = false
    }

    return basket;
  },
}

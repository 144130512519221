<template>
<div class="order-table">
  <table>
    <thead>
      <tr>
        <td class="w-auto">{{ title }}</td>
        <td class="w-100">Verfügbar.</td>
        <td class="w-150">PREISE</td>
        <td class="w-50">ANZAHL</td>
        <td class="w-50"></td>
      </tr>
    </thead>
    <tbody>
      <row v-for="item in items" :key="item.id" @change="updateSelected" :quantity="getQuantityFor(item.id)" :data="item"></row>
    </tbody>
  </table>
</div>
</template>

<script>
import Row from './Row'

export default {
  components: {
    Row
  },
  props: {
    title: {
      default: null,
      type: [String]
    },
    items: {
      default: null,
      type: [Array]
    },
    value: {
      default: () => [],
      type: [Array]
    }
  },

  data() {
    return {
      lazySelected: this.value
    }
  },

  computed: {
    internalSelected: {
      get() {
        return this.lazySelected
      },
      set(val) {
        this.lazySelected = val;
        this.$emit('input', val);
      }
    }
  },

  watch: {
    value (val) {
      this.lazySelected = val
    }
  },

  methods: {
    getQuantityFor(id) {
      let item = this.lazySelected.filter(i => i.id === id).pop()
      return item ? item.quantity : 0
    },
    updateSelected(item) {
      let selected = [...this.lazySelected]
      let insertedItem = selected.filter(i => i.id === item.id).pop()

      if (insertedItem) {
        let index = selected.indexOf(insertedItem)
        item.quantity > 0
          ? selected[index] = item
          : selected.splice(index, 1)
      } else {
        item.quantity > 0 && selected.push(item)
      }

      this.internalSelected = selected
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "step-four" }, [
    _c(
      "section",
      { staticClass: "sponsoring-tables-section" },
      [
        _c("h2", { staticClass: "section-heading" }, [_vm._v("Teilnehmer*in")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0" }, [
          _vm._v(
            "Aufgrund Ihrer Auswahl können Sie bis zu " +
              _vm._s(_vm.maxParticipants) +
              " Teilnehmer*in anmelden."
          ),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("*Pflichtfelder")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("infobox", [
          _vm._v(
            "\n      Die Anmeldung Ihrer Teilnehmer:innen können Sie durch Überspringen auch auf einen späteren Zeitpunkt verschieben. Die Gültigkeit Ihrer Buchung ist dadurch nicht betroffen. Die Nachtragungen bzw. Abänderungen sind bis eine Woche vor der Tagung möglich.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-blue-dark",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.skip("next")
                },
              },
            },
            [_vm._v("Überspringen")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line-spacer-blue" }),
        _vm._v(" "),
        _c("participants", {
          attrs: { max: _vm.maxParticipants, form: _vm.form },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm.availability
            ? _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "infobox",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v("Fehler!")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        747931340
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm._l(_vm.availability, function (product) {
                        return _c("span", { key: product.id }, [
                          _c("strong", [_vm._v(_vm._s(product.description))]),
                          _vm._v(" ist bereits ausgebucht."),
                          _c("br"),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.info
      ? _c("section", { staticClass: "info-section mt-30" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "infobox",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.info.subtitle))]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3688776340
                    ),
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.info.description) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.selectedSponsorPackage !== null || _vm.selectedSpecialItems.length
      ? _c(
          "section",
          { staticClass: "summary-section mt-30 mb-30" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("sponsorship-summary", {
              attrs: { details: true, editable: true },
            }),
            _vm._v(" "),
            _c("exhibit-summary", { attrs: { details: false } }),
            _vm._v(" "),
            _c("special-summary", {
              attrs: { details: false, editable: false, headers: false },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "button-section text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-blue-light mr-1",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.skip("back")
            },
          },
        },
        [_vm._v("Zurück")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-blue-dark",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.next.apply(null, arguments)
            },
          },
        },
        [_vm._v("Weiter")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "section-heading" }, [
      _c("span", { staticClass: "shopping-cart-icon mr-10" }),
      _vm._v("Warenkorb"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
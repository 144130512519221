import to from '@Utils/to'
import Form from '@Utils/Form'
import { getField, updateField } from 'vuex-map-fields'

const sponsorship = {
  namespaced: true
}

// STATE
sponsorship.state = {
  selected: {
    advertisements: [],
    other: []
  }
}

// MUTATIONS
sponsorship.mutations = {
  updateField,
  advertisements(state, items) {
    state.selected.advertisements = items
  },
  other(state, items) {
    state.selected.other = items
  }
}

// ACTIONS
sponsorship.actions = {
  removeItem ({ state: { selected }, commit }, id) {
    const { advertisements, other } = selected;

    // - Remove advertisment
    var item = advertisements.filter(item => item.id === id).pop()
    if (item) {
      let items = [...advertisements];
      items.splice(advertisements.indexOf(item), 1);
      commit('advertisements', items)
    }

    // - or Remove other
    var item = other.filter(item => item.id === id).pop()
    if (item) {
      let items = [...other];
      items.splice(other.indexOf(item), 1);
      commit('other', items)
    }
  },
  async checkAvailability({ dispatch, getters: { items } }) {
    if (items.length === 0) {
      return false
    }

    const data = {
      products: items.map(item => item.id),
      quantities: items.map(item => {
        return { id: item.id, quantity: item.quantity }
      })
    }
    const form = new Form(data)

    let [
      error,
      status
    ] = await to(
      form.post('/check-quantities')
    )

    if (error) {
      console.info('[Quantity Check Failed]', error)
      return false
    }

    if (status.success) {
      status.unavailable.map(item => dispatch('removeItem', item.id))
      dispatch('data/updateData', {
        module: 'sponsorship',
        data: status.sponsorship
      }, { root: true })
    }

    return status.success ? status.unavailable : false;
  }
}

// GETTERS
sponsorship.getters = {
  getField,
  items: ({ selected }) => {
    const { advertisements, other } = selected;
    return advertisements.concat(other).map(item => {
      return {...item, total: item.price * item.quantity }
    })
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items.reduce((total, item) => total + item.total, 0)
  },
  brutto: (state, getters) => {
    return Math.round(
      getters.items.reduce((total, item) => total + (item.total * (1 + item.tax / 100)), 0) * 100
    ) / 100
  },
}

export default sponsorship;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: _vm.classList,
      style: {
        "background-color": _vm.data.product_in_shop === 0 ? "#f5c4c4" : "",
      },
    },
    [
      _c("td", { staticClass: "text-center" }, [
        _c("b", [_vm._v(_vm._s(_vm.data.nr))]),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "title-cell" },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.data.description) } }),
          _vm._v(" "),
          _vm.data.product_description
            ? _c("i", {
                staticClass: "mdi mdi-information mdi-24px",
                staticStyle: { float: "right" },
                on: { click: _vm.openModal },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.data.soldOut
            ? _c("small", { staticClass: "text-blue-dark" }, [
                _vm._v("Ausgebucht"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.info
            ? _c(
                "tooltip",
                {
                  attrs: {
                    trigger: "hover",
                    options: { placement: "right-start" },
                  },
                },
                [
                  _c("div", { staticClass: "tooltip" }, [
                    _c("div", {
                      staticClass: "tooltip-inner",
                      domProps: { innerHTML: _vm._s(_vm.data.info) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-circle-icon",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.company !== undefined
        ? _c("td", { staticClass: "company-cell" }, [
            _vm._v(_vm._s(_vm.data.company || " ")),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "text-center" },
        [
          _vm.available
            ? _c("v-checkbox", {
                attrs: {
                  name: "",
                  disabled: _vm.disabled || _vm.data.product_in_shop === 0,
                },
                model: {
                  value: _vm.internalChecked,
                  callback: function ($$v) {
                    _vm.internalChecked = $$v
                  },
                  expression: "internalChecked",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal", class: { "is-active": _vm.isModalOpen } },
        [
          _c("div", {
            staticClass: "modal-background",
            on: { click: _vm.closeModal },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.data.product_description) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="step-five">
    <section class="info-section" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>
    <section class="basket-section mt-30 mb-30">
      <order-summary></order-summary>
    </section>
    <section class="sponsorship-check-section mb-30" v-if="availability">
      <div class="row">
        <div class="col-md-12">
          <infobox class="infobox--alert">
            <template v-slot:title>Fehler!</template>
            <span v-for="product in availability" :key="product.id">
              <strong>{{ product.description }}</strong> ist bereits ausgebucht.<br>
            </span>
          </infobox>
        </div>
      </div>
    </section>
    <section class="highlight-section mt-70">
      <div class="row">
        <div class="col-md-12">
          <div class="highlight mb-30 text-justify">
            <div class="row">
              <div class="col-md-12">
                  <v-checkbox v-model="privacy" name="privacy" :error="error('agreements.privacy')" error-message="Diese Checkbox ist ein Pflichtfeld">
                    Hiermit bestätige ich die <a href="https://www.kleinwasserkraft.at/top/datenschutz/" target="_blank">Datenschutzbestimmungen</a>
                  </v-checkbox>
              </div>
              <div class="col-md-12">
                  <v-checkbox v-model="agb" name="agb" :error="error('agreements.agb')" error-message="Diese Checkbox ist ein Pflichtfeld">
                    Hiermit bestätige ich die <a :href="agb_link" target="_blank">AGB</a>
                  </v-checkbox>
              </div>
            </div>
          </div>
        </div>
    </div>
    </section>
    <section class="finish-checkout--blank text-right">
      <a v-if="loading || checking" class="btn btn-dark" href="#" role="button" :disabled="loading || checking">Bitte warten</a>
      <a v-else class="btn btn-blue-dark"  href="#" role="button" @click.prevent="prepare">
        <template v-if="loggedIn">Buchung aktualisieren</template>
        <template v-else>Buchen</template>
      </a>
    </section>
    <!-- <section class="finish-checkout">
      <a v-if="empty" class="btn btn-dark" href="#" role="button" :disabled="empty">kostenpflichtig bestellen</a>
      <a v-else-if="loading || checking" class="btn btn-dark" href="#" role="button" :disabled="loading || checking">Bitte warten</a>
      <a v-else class="btn btn-blue-dark"  href="#" role="button" @click.prevent="prepare">kostenpflichtig bestellen</a>
    </section> -->
  </section>
</template>

<script>
import { Infobox } from '../components'
import { VCheckbox } from '../components/Form'
import { OrderSummary } from '../components/Checkout'
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const { mapActions: mapDataActions } = createNamespacedHelpers('data')

const {
  mapState: mapBasketState,
  mapActions: mapBasketActions,
} = createNamespacedHelpers('basket')

const {
  mapGetters: mapExhibitGetters,
} = createNamespacedHelpers('basket/exhibit')

const {
  mapGetters: mapSymposiumGetters,
} = createNamespacedHelpers('basket/symposium')

const {
  mapActions: mapSponsorshipActions,
  mapGetters: mapSponsorshipGetters,
} = createNamespacedHelpers('basket/sponsorship')

const {
  mapActions: mapSpecialPackageActions,
} = createNamespacedHelpers('basket/specialpackage')


export default {
  components: {
    Infobox,
    VCheckbox,
    OrderSummary,
  },

  data () {
    return {
      info: null,
      checking: false,
      availability: null,
    }
  },

  computed: {
    ...mapBasketState(['loading', 'response']),
    ...mapExhibitGetters({ exhibitItems: 'items' }),
    ...mapSymposiumGetters({ symposiumItems: 'items' }),
    ...mapSponsorshipGetters({ sponsorshipItems: 'items' }),
    ...mapFields('basket/agreements', [
      'agb',
      'privacy'
    ]),
    ...mapFields('basket/user', {
      loggedIn: 'loggedIn',
    }),

    agb_link() {
      return `${App.config.url}/uploads/AGB.pdf`;
    },

    empty() {
      return (
        this.exhibitItems.length === 0 &&
        this.symposiumItems.length === 0 &&
        this.sponsorshipItems.length === 0
      )
    }
  },

  async created () {
    this.info = await this.infoForStep(5)
  },

  methods: {
    ...mapBasketActions(['submit']),
    ...mapDataActions(['infoForStep']),
    ...mapSpecialPackageActions(['checkAvailability']),

    async prepare() {
      this.checking = true;
      const response = await this.checkAvailability()


      this.checking = false;
      this.availability = response
      response && window.scrollTo({ top: 250, behavior: 'smooth' });
      response === false && this.submit()
    },

    demoFinish() {
      this.checking = true;
      setTimeout(() => {
        this.checking = false;
        this.$router.push({ name: 'thankyou' })
      }, 1500);
    },

    error(field, returnMessage = false) {
      return this.response && this.response.errors.has(field)
        ? returnMessage && this.response.errors.get(field) || true
        : false
    },
  }
}
</script>


import * as ls from '@Utils/local-storage'

const createPlugin = storageKey => {
  const LocalStoragePlugin = store => {

    if (!ls.isAvailable()) {
      return false
    }

    // clear data if it is older than a day
    ls.isOutdated(storageKey) && ls.clear(storageKey)

    // Update local storage on change
    store.subscribe((mutation, state) => {
      ls.update(state, storageKey)
    })

  }
  return LocalStoragePlugin;
}

export default createPlugin

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter);

export default class Router extends VueRouter {
  constructor() {
    super({
      routes,
      mode: 'history',
      root: '/'
    });
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.groupClassList },
    [
      _c("v-label", {
        attrs: { name: _vm.name, label: _vm.label, tooltip: _vm.tooltip },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group spinner" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "user-area" },
          domProps: { value: _vm.numericValue },
          on: { input: _vm.inputValue, keypress: _vm.validateInput },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _vm.unit
            ? _c("span", {
                staticClass: "input-group-text",
                domProps: { innerHTML: _vm._s(_vm.unit) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "input-group-text buttons" }, [
            _c(
              "a",
              {
                staticClass: "spinner-button spinner-button-up",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.increaseNumber.apply(null, arguments)
                  },
                  mousedown: function ($event) {
                    _vm.delayCall(250, () =>
                      _vm.whileMouseDown(_vm.increaseNumber)
                    )
                  },
                  mouseup: _vm.clearTimer,
                },
              },
              [_vm._v("+")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "spinner-button spinner-button-down",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.decreaseNumber.apply(null, arguments)
                  },
                  mousedown: function ($event) {
                    _vm.delayCall(250, () =>
                      _vm.whileMouseDown(_vm.decreaseNumber)
                    )
                  },
                  mouseup: _vm.clearTimer,
                },
              },
              [_vm._v("-")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("v-error", { attrs: { message: _vm.errorMessage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
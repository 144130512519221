<template>
  <div class="participants">
    <div class="participant--wrapper mt-4" v-for="(participant, index) in internalParticipants" :key="index">
      <h2 class="section-heading">
        <template v-if="isOnline(index)">Online</template>
        Teilnehmer*in {{ index + 2 }}
        <small class="pull-right text-blue-dark" style="font-weight:bold; cursor:pointer;" @click.prevent="removeParticipant(index)">x</small>
      </h2>
      <participant v-model="internalParticipants[index]" :online="isOnline(index)" :index="index" :data="participant" :form="form"></participant>
    </div>
    <div class="bt-blue bb-blue pt-1 pb-3 clearfix mb-5">
      <button v-if="this.internalParticipants.length < this.max" class="btn btn-blue-dark pull-right" @click.prevent="addParticipant">Weitere Teilnehmer*in</button>
      <p class="m-0">Aufgrund Ihrer Auswahl können Sie bis zu {{ this.max + 1 }} Teilnehmer*in anmelden.</p>
    </div>
  </div>
</template>

<script>
import clone from 'lodash/cloneDeep'
import Participant from './Participant'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    Participant
  },
  props: {
    form: {
      default: null,
    },
    max: {
      default: 1,
      type: Number
    },
    onlineParticipants: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      internalParticipants: []
    }
  },
  created() {
    this.internalParticipants = clone(this.participants);
  },
  computed: {
    ...mapFields('basket/participants', {
      participants: 'participants'
    }),
  },
  methods: {
    addParticipant() {
      if (this.internalParticipants.length < this.max) {
        this.internalParticipants.push({
          title: '',
          postnominal: '',
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          eveningEvent: null,
          package: null
        })
      }
    },
    removeParticipant(index) {
      this.internalParticipants.splice(index, 1);
    },
    isOnline(index) {
      if (this.onlineParticipants == 0) {
        return false;
      }
      if (index < this.onlineParticipants - 1) {
        return true;
      }

      return false;
    }
  },
  watch: {
    max: {
      handler(val) {
        if (this.internalParticipants.length > val) {
          this.internalParticipants.splice(val, this.internalParticipants.length - val);
          this.participants = clone(this.internalParticipants);
        }
      }
    },
    internalParticipants: {
      deep: true,
      handler: function (val) {
          this.participants = clone(val)
      }
    }
  }
}
</script>

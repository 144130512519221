var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slot-booking-section bb-dark mb-30" }, [
    _c("h2", { staticClass: "section-heading" }, [_vm._v("Messestand")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: "/images/booth-map.jpg", alt: "Messestande" },
            on: {
              click: function ($event) {
                _vm.lightbox = true
              },
            },
          }),
          _vm._v(" "),
          _c("lightbox", {
            attrs: { visible: _vm.lightbox, imgs: "/images/booth-map.jpg" },
            on: { hide: () => (this.lightbox = false) },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-4",
          staticStyle: {
            "justify-content": "flex-end",
            display: "flex",
            "flex-direction": "column",
          },
        },
        [
          _c("b", [_vm._v("Wählen Sie Ihren Messestand")]),
          _c("br"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Auf der Messestandkarte finden Sie alle Plätze. Die Standbezeichnung inkl. Beschreibung plus Nummer finden Sie auf unserer unten angeführten Auswahlliste."
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-blue-dark",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.lightbox = true
                },
              },
            },
            [_vm._v("Vergrößern")]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pb-5 pt-5" }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.error
        ? _c(
            "div",
            { ref: "error", staticClass: "col-md-12" },
            [
              _c(
                "infobox",
                {
                  staticClass: "infobox--alert",
                  attrs: { icon: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [_vm._v("Fehler!")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    747931340
                  ),
                },
                [
                  _vm._v(
                    "\n          Sie müssen einen Messestand ausählen.\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "form-group is-filled" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Suche nach Firma..." },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("checkbox-table", {
            attrs: { headers: _vm.headers, items: _vm.items, single: true },
            model: {
              value: _vm.selectedPackages,
              callback: function ($$v) {
                _vm.selectedPackages = $$v
              },
              expression: "selectedPackages",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
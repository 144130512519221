<template>
  <div class="participants">
    <div class="participant--wrapper mt-4" v-for="(participant, index) in internalParticipants" :key="index">
      <h2 v-if="index > 0" class="section-heading">
        Teilnehmer*in {{ index + 1 }}
        <small class="pull-right text-blue-dark" style="font-weight:bold; cursor:pointer;" @click.prevent="removeParticipant(index)">x</small>
      </h2>
      <participant v-model="internalParticipants[index]" :data="participant" :index="index" :form="form"></participant>
    </div>
    <div class="bt-blue bb-blue pt-1 pb-3 clearfix mb-5">
      <button class="btn btn-blue-dark pull-right" @click.prevent="addParticipant" v-if="participants.length < max">Nächster Teilnehmer*in</button>
      <p class="m-0">Aufgrund Ihres Paket können sich bis zu {{ max }} Teilnehmer*in anmelden</p>
    </div>
  </div>
</template>

<script>
import clone from 'lodash/cloneDeep'
import Participant from './Participant'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    Participant
  },
  props: {
    form: {
      default: null,
    },
    max: {
      default: 1,
      type: Number
    }
  },
  data() {
    return {
      internalParticipants: []
    }
  },
  created() {
    this.internalParticipants = clone(this.participants);
    if (this.internalParticipants.length === 0) {
      this.addParticipant()
    }
    this.internalParticipants.forEach((participant, index) => {
      if ((typeof participant.company === 'string' && participant.company.length === 0) || typeof participant.company !== 'string') {
        this.internalParticipants[index].company = this.company.name
      }
    })
  },
  computed: {
    ...mapFields('basket/user', {
      company: 'company'
    }),
    ...mapFields('basket/participants', {
      participants: 'participants'
    }),
  },
  methods: {
    addParticipant() {
      if (this.internalParticipants.length < this.max) {
        this.internalParticipants.push({
          title: '',
          postnominal: '',
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          eveningEvent: null,
          company: this.company.name,
          package: null
        })
      }
    },
    removeParticipant(index) {
      this.internalParticipants.splice(index, 1);
    }
  },
  watch: {
    internalParticipants: {
      deep: true,
      handler: function (val) {
          this.participants = clone(val)
      }
    }
  }
}
</script>

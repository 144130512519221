var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "checkout-section" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "summary-section mt-30 mb-30" },
      [_c("exhibit-summary", { attrs: { details: true } })],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "summary-section mt-30 mb-30" },
      [_c("special-summary", { attrs: { details: true, headers: false } })],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "summary-section" },
      [_c("sponsorship-summary", { attrs: { details: true, headers: false } })],
      1
    ),
    _vm._v(" "),
    _vm.brutto === 0
      ? _c("section", { staticClass: "summary-section cartEmpty mb-30" }, [
          _vm._v("\n    Ihr Warenkorb ist leer\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "checkout-cart summary-section" }, [
      _c("table", { staticClass: "headers" }, [
        _c("thead", [
          _c("tr", { staticClass: "text-blue-dark bt-none bb-dark" }, [
            _c("td", { staticClass: "w-auto" }, [_vm._v("Gesamtsumme")]),
            _vm._v(" "),
            _c("td", { staticClass: "w-150 text-right" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.brutto))),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "section-heading" }, [
      _c("span", { staticClass: "shopping-cart-icon mr-10" }),
      _vm._v("Warenkorb"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "checkout-cart mt-70" }, [
      _c("table", { staticClass: "headers" }, [
        _c("thead", [
          _c("tr", { staticClass: "text-blue-dark" }, [
            _c("td", { staticClass: "w-auto" }, [_vm._v("Produkte")]),
            _vm._v(" "),
            _c("td", { staticClass: "w-50" }, [_vm._v("Menge")]),
            _vm._v(" "),
            _c("td", { staticClass: "w-150 text-center" }, [_vm._v("Preis")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
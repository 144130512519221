var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slot-booking-section bb-dark mb-30" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("order-table", {
            attrs: { title: "Spezialpakete", items: _vm.items },
            model: {
              value: _vm.selectedPackages,
              callback: function ($$v) {
                _vm.selectedPackages = $$v
              },
              expression: "selectedPackages",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const navigation = {
  namespaced: true
}

// STATE
navigation.state = {
}

// MUTATIONS
navigation.mutations = {
}

// ACTIONS
navigation.actions = {
}

// GETTERS
navigation.getters = {
}

export default navigation

<template>
  <div :class="checkClassList">
    <label class="checkbox-switch">
      <input type="checkbox" v-model="internalValue">
      <span class="slider"></span>
    </label>
    <span class="checkbox-label" @click="internalValue = !internalValue">
      {{ label }}
      <tooltip
        v-if="tooltip"
        trigger="hover"
        :options="{ placement: 'right-start' }">
        <div class="tooltip">
          <div class="tooltip-inner" v-html="tooltip"></div>
        </div>
        <span class="info-circle-icon" slot="reference"></span>
      </tooltip>
    </span>
    <v-error :message="errorMessage"></v-error>
  </div>
</template>

<script>
import Tooltip from '../Tooltip'
import { VError } from '../Form'

export default {
  components: {
    VError,
    Tooltip
  },
  props: {
    value: {
      default: ''
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    error: {
      default: null,
      type: [Boolean]
    },
    errorMessage: {
      default: null,
      type: [String]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },

  data () {
    return {
      lazyValue: this.value
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },

    checkClassList () {
      return {
        'form-check': true,
        'has-error': this.error,
        'is-filled': this.internalValue
      }
    }
  },

  watch: {
    value (val) {
      this.lazyValue = val
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "participant" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: { tabindex: 0, name: "company", label: "Firmenname" },
              model: {
                value: _vm.participant.company,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "company", $$v)
                },
                expression: "participant.company",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: { tabindex: 1, name: "titel", label: "Titel" },
              model: {
                value: _vm.participant.title,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "title", $$v)
                },
                expression: "participant.title",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: {
                tabindex: 2,
                name: "titel-after",
                label: "Titel nachgestellt",
              },
              model: {
                value: _vm.participant.postnominal,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "postnominal", $$v)
                },
                expression: "participant.postnominal",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: {
                error: _vm.error(`participants.${_vm.index}.firstname`),
                "error-message": _vm.error(
                  `participants.${_vm.index}.firstname`,
                  true
                ),
                tabindex: 3,
                name: "firstname",
                label: "Vorname*",
              },
              model: {
                value: _vm.participant.firstname,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "firstname", $$v)
                },
                expression: "participant.firstname",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: {
                error: _vm.error(`participants.${_vm.index}.lastname`),
                "error-message": _vm.error(
                  `participants.${_vm.index}.lastname`,
                  true
                ),
                tabindex: 4,
                name: "lastname",
                label: "Nachname*",
              },
              model: {
                value: _vm.participant.lastname,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "lastname", $$v)
                },
                expression: "participant.lastname",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: { tabindex: 5, name: "phone", label: "Telefon" },
              model: {
                value: _vm.participant.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "phone", $$v)
                },
                expression: "participant.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-input", {
              attrs: {
                error: _vm.error(`participants.${_vm.index}.email`),
                "error-message": _vm.error(
                  `participants.${_vm.index}.email`,
                  true
                ),
                tabindex: 6,
                name: "email",
                label: "E-Mail*",
              },
              model: {
                value: _vm.participant.email,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "email", $$v)
                },
                expression: "participant.email",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("v-select", {
              attrs: {
                error: _vm.error(`participants.${_vm.index}.eveningEvent`),
                "error-message": _vm.error(
                  `participants.${_vm.index}.eveningEvent`,
                  true
                ),
                tabindex: 7,
                name: "abendveranstaltung",
                label: "Abendveranstaltung*",
                options: [
                  { label: "Ja", code: "true" },
                  { label: "Nein", code: "false" },
                ],
              },
              model: {
                value: _vm.participant.eveningEvent,
                callback: function ($$v) {
                  _vm.$set(_vm.participant, "eveningEvent", $$v)
                },
                expression: "participant.eveningEvent",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "form-section" },
      [
        _c("p", { staticClass: "m-0" }, [_vm._v("Programmpakete*")]),
        _vm._v(" "),
        _vm.error(`participants.${_vm.index}.package`)
          ? _c("div", { staticClass: "form-group has-error" }, [
              _c("div", { staticClass: "invalid-tooltip" }, [
                _vm._v(
                  _vm._s(_vm.error(`participants.${_vm.index}.package`, true))
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("checkbox-table", {
          staticClass: "mt-5",
          attrs: { online: _vm.online, items: _vm.items, single: true },
          model: {
            value: _vm.participantPackage,
            callback: function ($$v) {
              _vm.participantPackage = $$v
            },
            expression: "participantPackage",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="nav-container" v-if="currentRoute !== 'thankyou'">
  <h1>{{ title }}</h1>
  <nav id="steps-navigation">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item" :class="[activeSteps[currentRoute] && activeSteps[currentRoute].includes('index') ? 'active' : '']">
        <router-link :to="{ name: 'index'}" class="nav-drop" tag="span">
          <span class="nav-circle">1</span>
        </router-link>
      </li>
      <li class="nav-item" :class="[activeSteps[currentRoute] && activeSteps[currentRoute].includes('step-2') ? 'active' : '']">
        <router-link :to="{ name: 'step-2'}" class="nav-drop" tag="span">
          <span class="nav-circle">2</span>
        </router-link>
      </li>
    </ul>
  </nav>
</div>
</template>

<script>
export default {
  data() {
    return {
      activeSteps: {
        'index' : ['index'],
        'step-2': ['index', 'step-2'],
        'step-3': ['index', 'step-2', 'step-3'],
        'step-4': ['index', 'step-2', 'step-3', 'step-4'],
        'thankyou': [],
      }
    }
  },

  created() {
      console.log(this.$route.name);
  },

  computed: {
    title() {
      return this.$route.meta.title;
    },
    currentRoute() {
      return this.$route.name;
    }
  }

}
</script>

<style lang="scss" scoped>
  nav {
    > ul li::before {
      content: none;
    }
  }
</style>

<template>
  <div :class="groupClassList">
    <v-label :name="name" :label="label" :tooltip="tooltip"></v-label>
    <v-select :id="name" v-model="internalValue" :tabindex="tabindex" :clearable="false" :options="options" :disabled="disabled">
      <template slot="selected-option" slot-scope="option">
        <span v-html="option.label"></span>
      </template>
      <template slot="option" slot-scope="option">
        <span v-html="option.label"></span>
      </template>
      <template slot="no-options">
        {{ noOptions }}
      </template>
    </v-select>
    <v-error :message="errorMessage"></v-error>
  </div>
</template>

<script>
import { VLabel, VError } from '../Form'
import VSelect from 'vue-select'

export default {
  components: {
    VLabel,
    VError,
    VSelect
  },
  props: {
    tabindex: {
      default: null,
      type: [Number]
    },
    value: {
      default: null
    },
    options: {
      required: true,
      type: [Array]
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    error: {
      default: null,
      type: [Boolean]
    },
    errorMessage: {
      default: null,
      type: [String, Boolean]
    },
    tooltip: {
      default: null,
      type: [String]
    },
    noOptions: {
      default: 'Keine optionen.',
      type: [String]
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      hover : false,
      lazyValue: this.value
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },
    groupClassList () {
      return {
        'form-group': true,
        'has-error': this.error,
        'is-filled': this.lazyValue || this.hover
      }
    }
  },

  watch: {
    value (val) {
      this.lazyValue = val
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.groupClassList },
    [
      _c("v-label", {
        attrs: { name: _vm.name, label: _vm.label, tooltip: _vm.tooltip },
      }),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalValue,
            expression: "internalValue",
          },
        ],
        staticClass: "form-control",
        style: _vm.style,
        attrs: { name: _vm.name, id: _vm.name },
        domProps: { value: _vm.internalValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.internalValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "textarea-info" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("v-error", { attrs: { message: _vm.errorMessage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: _vm.classList }, [
    _c(
      "td",
      [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.data.description) } }),
        _vm._v(" "),
        _vm.data.info
          ? _c(
              "tooltip",
              {
                attrs: {
                  trigger: "hover",
                  options: { placement: "right-start" },
                },
              },
              [
                _c("div", { staticClass: "tooltip" }, [
                  _c("div", {
                    staticClass: "tooltip-inner",
                    domProps: { innerHTML: _vm._s(_vm.data.info) },
                  }),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "info-circle-icon",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.data.quantity <= 0
          ? _c("small", { staticClass: "text-blue-dark" }, [
              _vm._v("Ausgebucht"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.product_description
          ? _c("i", {
              staticClass: "mdi mdi-information mdi-24px",
              staticStyle: { float: "right" },
              on: { click: _vm.openModal },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "pl-20 pr-20" }, [
      _c("span", { staticClass: "quantity" }, [
        _vm._v("Stk. " + _vm._s(Math.max(0, _vm.data.quantity))),
      ]),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "pl-20 pr-20" }, [
      _c("span", { staticClass: "price" }, [
        _vm._v(_vm._s(_vm._f("currency")(_vm.data.price))),
      ]),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "text-center" }, [
      _c("span", { staticClass: "quantity" }, [_vm._v(_vm._s(_vm.qty))]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "p-none" },
      [
        _vm.data.quantity > 0
          ? _c("simple-spinner", {
              attrs: { max: _vm.data.quantity },
              model: {
                value: _vm.qty,
                callback: function ($$v) {
                  _vm.qty = $$v
                },
                expression: "qty",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal", class: { "is-active": _vm.isModalOpen } },
      [
        _c("div", {
          staticClass: "modal-background",
          on: { click: _vm.closeModal },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.data.product_description) },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { getField, updateField } from 'vuex-map-fields'

const user = {
  namespaced: true
}

// STATE
user.state = {
  useInvoiceAddress: false,
  hasCompany: false,
  loggedIn: false,
  company: {
    name: null,
    street: null,
    city: null,
    zip: null,
    country: null,
    uid: null,
    sponsor: null,
    contract: null,
    member: null,
  },
  invoice: {
    name: null,
    street: null,
    city: null,
    zip: null,
    country: null,
  },
  contact: {
    sex: null,
    title: null,
    firstname: null,
    lastname: null,
    postnominal: null,
    phone: null,
    mobile: null,
    email: null,
    fax: null,
  },
}

// MUTATIONS
user.mutations = {
  updateField,
}

// ACTIONS
user.actions = {
}

// GETTERS
user.getters = {
  getField,
  isMember: state => state.company.member !== null && state.company.member.code === true
}

export default user;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "step-two" },
    [
      _c(
        "section",
        { staticClass: "form-section mb-30" },
        [
          _c("h2", { staticClass: "section-heading" }, [
            _vm._v("Buchung Ausstellungsfläche:"),
          ]),
          _vm._v(" "),
          _vm.isProductAvailable
            ? [
                _c("p", [
                  _vm._v(
                    "\n        Mindestausstellungsfläche: 6 m² zu " +
                      _vm._s(_vm._f("currency")(_vm.flatrate.price)) +
                      " | pro weiterem Quadratmeter = " +
                      _vm._s(_vm._f("currency")(_vm.additional.price)) +
                      "\n        "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Preise exklusive Stromanschluss-/verbrauch, Mobiliar, Trennwände, Standreinigung, odgl."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("v-spinner", {
                        attrs: {
                          name: "bodenflaeche",
                          label: "Bodenfläche: ",
                          min: _vm.minArea,
                          step: 1,
                          tooltip: "Die georderten Maße gelten als Außenmaße.",
                          unit: "m<sup>2</sup>",
                        },
                        on: { input: _vm.updateDimensions },
                        model: {
                          value: _vm.area,
                          callback: function ($$v) {
                            _vm.area = $$v
                          },
                          expression: "area",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("v-spinner", {
                        attrs: {
                          name: "length",
                          min: _vm.minLength,
                          label: "Länge: ",
                          tooltip:
                            "Ihre Wunschmaße können hier eingegeben/verändert werden",
                          unit: "m",
                        },
                        on: { input: _vm.updateArea },
                        model: {
                          value: _vm.length,
                          callback: function ($$v) {
                            _vm.length = $$v
                          },
                          expression: "length",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("v-spinner", {
                        attrs: {
                          name: "depth",
                          min: _vm.minDepth,
                          label: "Tiefe: ",
                          tooltip:
                            "Ihre Wunschmaße können hier eingegeben/verändert werden",
                          unit: "m",
                        },
                        on: { input: _vm.updateArea },
                        model: {
                          value: _vm.depth,
                          callback: function ($$v) {
                            _vm.depth = $$v
                          },
                          expression: "depth",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isProductAvailable
        ? [
            _c(
              "section",
              { staticClass: "summary-section" },
              [
                _c("exhibit-summary", {
                  attrs: { details: true, editable: true },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("section", { staticClass: "form-section mt-70" }, [
              _c("h2", { staticClass: "section-heading" }, [
                _vm._v("Zusatzinformation:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "v-textarea",
                      {
                        attrs: {
                          name: "anmerkung",
                          label: "Besondere Anmerkungen:",
                        },
                        model: {
                          value: _vm.remarks,
                          callback: function ($$v) {
                            _vm.remarks = $$v
                          },
                          expression: "remarks",
                        },
                      },
                      [
                        _vm._v(
                          "\n            Bitte geben Sie an, ob Ihr Stand keinesfalls neben dem eines Mitbewerbers stehen soll od. ob eine spezielle Konstruktion, etc. geplant ist\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-25" },
                  [
                    _c(
                      "v-radio",
                      {
                        attrs: { name: "exhibit-option", id: "own-build" },
                        model: {
                          value: _vm.option,
                          callback: function ($$v) {
                            _vm.option = $$v
                          },
                          expression: "option",
                        },
                      },
                      [_vm._v("Wir bauen einen Eigenstand auf.")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-radio",
                      {
                        attrs: { name: "exhibit-option", id: "own-rollups" },
                        model: {
                          value: _vm.option,
                          callback: function ($$v) {
                            _vm.option = $$v
                          },
                          expression: "option",
                        },
                      },
                      [_vm._v("Wir verwenden einen Faltstand/Roll-Up(s).")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-radio",
                      {
                        attrs: { name: "exhibit-option", id: "own-stand" },
                        model: {
                          value: _vm.option,
                          callback: function ($$v) {
                            _vm.option = $$v
                          },
                          expression: "option",
                        },
                      },
                      [
                        _vm._v(
                          "Wir werden einen Standard-Systemstand gegen Extragebühren bestellen."
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.info
        ? _c("section", { staticClass: "info-section" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "infobox",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.info.subtitle))]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3688776340
                      ),
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.description) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "button-section text-right" }, [
        _vm.area == 0
          ? _c(
              "button",
              {
                staticClass: "btn btn-dark",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.next.apply(null, arguments)
                  },
                },
              },
              [_vm._v("überspringen")]
            )
          : _c(
              "button",
              {
                staticClass: "btn btn-blue-dark",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.next.apply(null, arguments)
                  },
                },
              },
              [_vm._v("weiter")]
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="slotbox">
    <div class="slotbox-header">
      <div class="slotbox-title">
        {{ title }}:
        <tooltip
          v-if="tooltip"
          trigger="hover"
          :options="{ placement: 'right-start' }">
          <div class="tooltip">
            <div class="tooltip-inner" v-html="tooltip"></div>
          </div>
          <span class="info-circle-icon" slot="reference"></span>
        </tooltip>
      </div>
      <div class="slotbox-subtitle">
        {{ duration }} <span class="text-blue-dark">|</span> {{ price | currency }}
      </div>
    </div>
    <div class="slotbox-body">
      <table>
        <tbody>
          <tr v-for="(slot, key) in slots" :key="key">
            <td v-html="slot.name"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Tooltip from './Tooltip'

export default {
  components: {
    Tooltip
  },
  props: {
    title: {
      required: true,
      type: [String]
    },
    duration: {
      default: null,
      type: [String, Number]
    },
    price: {
      default: null,
      type: [Number]
    },
    slots: {
      default: [],
      type: [Array]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },

  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

import { getField, updateField } from 'vuex-map-fields'

const exhibit = {
  namespaced: true
}

// STATE
exhibit.state = {
  area: 0,
  depth: 0,
  length: 0,
  option: null,
  remarks: null
}

// MUTATIONS
exhibit.mutations = {
  updateField,
  clear(state) {
    state.area = 0
    state.depth = 0
    state.length = 0
  }
}

// ACTIONS
exhibit.actions = {
  clear({ commit }) {
    commit('clear')
  }
}

// GETTERS
exhibit.getters = {
  getField,
  items: (state, getters, rootState) => {
    let items = [];
    if (state.area >= 6) {
      let quantity = state.area - 6;
      let { flatrate, additional } = rootState.data.exhibit
      items.push({ ...flatrate, quantity: 1, total: flatrate.price })
      quantity > 0 && items.push({ ...additional, quantity, total: additional.price * quantity })
    }
    return items
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items.reduce((total, item) => total + item.total, 0)
  },
  brutto: (state, getters) => {
    return Math.round(
      getters.items.reduce((total, item) => total + (item.total * (1 + item.tax / 100)), 0) * 100
    ) / 100
  },
}

export default exhibit;

import { getField, updateField } from 'vuex-map-fields'

const agreements = {
  namespaced: true
}

// STATE
agreements.state = {
  agb: false,
  privacy: false
}

// MUTATIONS
agreements.mutations = {
  updateField
}

// ACTIONS
agreements.actions = {
}

// GETTERS
agreements.getters = {
  getField
}

export default agreements;

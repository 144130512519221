<template>
  <div :class="groupClassList">
    <v-label :name="name" :label="label" :tooltip="tooltip"></v-label>
    <textarea class="form-control" v-model="internalValue" :name="name" :id="name" :style="style"></textarea>
    <span class="textarea-info"><slot></slot></span>
    <v-error :message="errorMessage"></v-error>
  </div>
</template>

<script>
import { VLabel, VError } from '../Form'

export default {
  components: {
    VLabel,
    VError
  },

  props: {
    value: {
      default: ''
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    error: {
      default: null,
      type: [Boolean]
    },
    errorMessage: {
      default: null,
      type: [String]
    },
    tooltip: {
      default: null,
      type: [String]
    },
    height: {
      default: null,
      type: [String]
    }
  },

  data () {
    return {
      lazyValue: this.value
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },
    groupClassList () {
      return {
        'form-group': true,
        'has-error': this.error,
        'is-filled': this.lazyValue && this.lazyValue.length
      }
    },
    style () {
      return this.height ? { minHeight: this.height } : null
    }
  },

  watch: {
    value (val) {
      this.lazyValue = val
    }
  },

}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "participants" },
    [
      _vm._l(_vm.internalParticipants, function (participant, index) {
        return _c(
          "div",
          { key: index, staticClass: "participant--wrapper mt-4" },
          [
            index > 0
              ? _c("h2", { staticClass: "section-heading" }, [
                  _vm._v(
                    "\n      Teilnehmer*in " + _vm._s(index + 1) + "\n      "
                  ),
                  _c(
                    "small",
                    {
                      staticClass: "pull-right text-blue-dark",
                      staticStyle: { "font-weight": "bold", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeParticipant(index)
                        },
                      },
                    },
                    [_vm._v("x")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("participant", {
              attrs: { data: participant, index: index, form: _vm.form },
              model: {
                value: _vm.internalParticipants[index],
                callback: function ($$v) {
                  _vm.$set(_vm.internalParticipants, index, $$v)
                },
                expression: "internalParticipants[index]",
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bt-blue bb-blue pt-1 pb-3 clearfix mb-5" }, [
        _vm.participants.length < _vm.max
          ? _c(
              "button",
              {
                staticClass: "btn btn-blue-dark pull-right",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addParticipant.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Nächster Teilnehmer*in")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "m-0" }, [
          _vm._v(
            "Aufgrund Ihres Paket können sich bis zu " +
              _vm._s(_vm.max) +
              " Teilnehmer*in anmelden"
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slotbox" }, [
    _c("div", { staticClass: "slotbox-header" }, [
      _c(
        "div",
        { staticClass: "slotbox-title" },
        [
          _vm._v("\n      " + _vm._s(_vm.title) + ":\n      "),
          _vm.tooltip
            ? _c(
                "tooltip",
                {
                  attrs: {
                    trigger: "hover",
                    options: { placement: "right-start" },
                  },
                },
                [
                  _c("div", { staticClass: "tooltip" }, [
                    _c("div", {
                      staticClass: "tooltip-inner",
                      domProps: { innerHTML: _vm._s(_vm.tooltip) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-circle-icon",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "slotbox-subtitle" }, [
        _vm._v("\n      " + _vm._s(_vm.duration) + " "),
        _c("span", { staticClass: "text-blue-dark" }, [_vm._v("|")]),
        _vm._v(" " + _vm._s(_vm._f("currency")(_vm.price)) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "slotbox-body" }, [
      _c("table", [
        _c(
          "tbody",
          _vm._l(_vm.slots, function (slot, key) {
            return _c("tr", { key: key }, [
              _c("td", { domProps: { innerHTML: _vm._s(slot.name) } }),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
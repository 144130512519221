<template>
  <section class="step-three">
    <sponsor-packages></sponsor-packages>
    <div class="pt-5 pb-5"></div>
    <special-packages></special-packages>
    <div class="pt-5 pb-5"></div>
    <exhibition-booths :error="errors.exhibit"></exhibition-booths>
    <section class="info-section" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>
    <section class="summary-section mt-30 mb-30" v-if="selectedSponsorPackage !== null || selectedSpecialItems.length">
      <h2 class="section-heading"><span class="shopping-cart-icon mr-10"></span>Warenkorb</h2>
      <exhibit-summary :details="true"></exhibit-summary>
      <special-summary :details="true" :editable="true"></special-summary>
    </section>
    <section class="button-section text-right">
      <button class="btn btn-blue-light mr-1" @click.prevent="skip('back')">Zurück</button>
      <button class="btn btn-blue-dark" @click.prevent="next">Weiter</button>
    </section>
  </section>
</template>

<script>
import { Infobox, Slotbox } from '../components'
import { VTextarea, VSelect } from '../components/Form'
import { SpecialSummary, ExhibitSummary } from '../components/Checkout'
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import SponsorPackages from '../components/Elements/SponsorPackages'
import SpecialPackages from '../components/Elements/SpecialPackages'
import ExhibitionBooths from '../components/Elements/ExhibitionBooths'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapGetters: mapExhibitGetters,
} = createNamespacedHelpers('basket/exhibit')

const {
  mapState: mapSymposiumState,
  mapActions: mapSymposiumActions,
} = createNamespacedHelpers('basket/symposium')

const {
  mapState: mapSponsorState,
} = createNamespacedHelpers('basket/sponsorpackage')

const {
  mapState: mapSpecialState,
} = createNamespacedHelpers('basket/specialpackage')

const {
  mapState: mapExhibitionState,
} = createNamespacedHelpers('basket/exhibitionBooth')

export default {
  components: {
    Infobox,
    Slotbox,
    VSelect,
    VTextarea,
    ExhibitSummary,
    SpecialSummary,
    SponsorPackages,
    SpecialPackages,
    ExhibitionBooths
  },

  data() {
    return {
      info: null,
      errors: {
        symposium: false,
        extra: false,
        slot: false,
        exhibit: false,
      },
    }
  },

  async created () {
    this.info = await this.infoForStep(3)
  },

  computed: {
    ...mapDataState(['packages']),
    ...mapSymposiumState(['added']),
    ...mapExhibitGetters({ exhibitItems: 'items' }),
    ...mapSpecialState({ selectedSpecialItems: 'selected' }),
    ...mapSponsorState({ selectedSponsorPackage: 'selected' }),
    ...mapExhibitionState({ selectedExhibitionBooth: 'selected' }),

    ...mapFields('basket/symposium', {
      selectedSymposium: 'selected.symposium',
      selectedSlot: 'selected.slot',
      selectedExtra: 'selected.extra',
      selectedTheme: 'selected.theme',
    }),

    symposiumOptions () {
      return this.symposium.map(item => {
        return {
          code: item.id,
          label: item.name
        }
      })
    },

    slotOptions () {
      return this.selectedSymposium
        ? this.symposium
          .filter(s => s.id === this.selectedSymposium.code)
          .map(s => {
            return s.slots.map(slot => {
              return {
                code: slot.name,
                label: slot.name
              }
            })
          }).pop()
        : []
    }
  },

  methods: {
    ...mapDataActions(['infoForStep']),
    ...mapSymposiumActions(['addSymposium']),

    add() {
      if (this.validate()) {
        this.addSymposium()
        this.clearSelection()
      }
    },
    validate() {
      this.errors.symposium = this.selectedSymposium ? false : 'Symposium muss ausgefüllt sein'
      this.errors.extra = this.selectedExtra ? false : ' Wunschslot II muss ausgefüllt sein'
      this.errors.slot = this.selectedSlot ? false : ' Wunschslot I muss ausgefüllt sein'

      return !this.errors.symposium && !this.errors.extra && !this.errors.slot
    },
    resetSelectedSlots() {
      this.selectedSlot = null;
      this.selectedExtra = null;
    },
    clearSelection() {
      this.selectedSlot = null;
      this.selectedExtra = null;
      this.selectedTheme = null;
      this.selectedSymposium = null;
    },
    skip (fn = 'next') {
      this.skipValidation = true
      this.participants = []
      this[fn]()
    },
    back() {
      this.$router.push({ name: 'index' })
    },
    next() {
      this.$router.push({ name: 'step-3' })
    },
  },

  beforeRouteLeave (to, from, next) {
    if (this.selectedExhibitionBooth || (this.selectedSponsorPackage && [1, 4].indexOf(this.selectedSponsorPackage.type) >= 0)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      next()
    } else {
      this.errors.exhibit = true
    }
  }
}
</script>


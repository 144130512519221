import App from './app'

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Bootstrap and other libraries. Transfered over from existing
 * website https://www.onconovum.academy/
 */

require('./bootstrap');

/**
 * Then we'll attach scripts from existing website for the template
 * transfered from https://www.onconovum.academy/
 */
require('./template');

/**
 * Next, we will create a fresh application instance and attach it to
 * the page. In there, we'll craft the application logic and components.
 */

const app = new App({
    el: '#app-root',
    type: 'company',
});

window.App = app;

const app2 = new App({
    el: '#participant-root',
    type: 'participant',
});

window.App2 = app2;

import to from '@Utils/to'
import Form from '@Utils/Form'
import { getField, updateField } from 'vuex-map-fields'

const specialpackage = {
  namespaced: true
}

// STATE
specialpackage.state = {
  selected: []
}

// MUTATIONS
specialpackage.mutations = {
  updateField,
  selected(state, items) {
    state.selected = items
  },
}

// ACTIONS
specialpackage.actions = {
  removeItem ({ state: { selected }, commit }, id) {
    var item = selected.filter(item => item.id === id).pop()
    if (item) {
      let items = [...selected];
      items.splice(selected.indexOf(item), 1);
      commit('selected', items)
    }
  },
  async checkAvailability({ dispatch, getters: { items } }) {
    if (items.length === 0) {
      return false
    }

    const data = {
      products: items.map(item => item.id),
      quantities: items.map(item => {
        return { id: item.id, quantity: item.quantity }
      })
    }
    const form = new Form(data)

    let [
      error,
      status
    ] = await to(
      form.post('/check-quantities')
    )

    if (error) {
      console.info('[Quantity Check Failed]', error)
      return false
    }

    if (status.success) {
      status.unavailable.map(item => dispatch('removeItem', item.id))
      dispatch('data/updateData', {
        module: 'packages',
        data: status.packages
      }, { root: true })
    }

    return status.success ? status.unavailable : false;
  }
}

// GETTERS
specialpackage.getters = {
  getField,
  items: ({ selected }, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';

    return selected ? selected.map(item => (
      {...item, total: item.prices['member'].price * item.quantity }
    )) : []
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.items.reduce((total, item) => total + item.total, 0)
  },
  brutto: (state, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';

    return Math.round(
      getters.items.reduce((total, item) => total + (item.total * (1 + item.prices['member'].tax / 100)), 0) * 100
    ) / 100
  },
}

export default specialpackage;

import data from './data'
import basket from './basket'
import navigation from './navigation'

export default () => {
  return {
    navigation,
    basket: basket.hydrated(),
    data: data.hydrated(),
  }
}

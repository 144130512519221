<template>
  <div class="checkout-cart" v-if="items && items.length">
    <table class="text-bold">
      <thead>
        <tr>
          <td class="title w-auto">
            Sponsoring
            <tooltip
              v-if="tooltip"
              trigger="hover"
              :options="{ placement: 'right-start' }">
              <div class="tooltip">
                <div class="tooltip-inner" v-html="tooltip"></div>
              </div>
              <span class="info-circle-icon" slot="reference"></span>
            </tooltip>
          </td>
          <td class="w-50"><template v-if="headers">Menge</template></td>
          <td class="w-150 text-right" :class="{ 'pr-20': editable }"><template v-if="headers">Preis</template></td>
          <td class="w-50" v-if="editable"></td>
        </tr>
      </thead>
      <tbody>
        <template v-if="details">
          <tr class="has-separators" v-for="item in items" :key="item.id">
            <td v-html="description(item)"></td>
            <td class="text-center">{{ item.quantity }}</td>
            <td class="text-right text-red-light pl-20" :class="{ 'pr-20': editable }"><span v-if="item.total">{{ item.total | currency }}</span><span v-else>Auf Anfrage</span></td>
            <td class="pl-20" v-if="editable"><span class="trash-icon" @click.prevent="removeItem(item.id)"></span></td>
          </tr>
        </template>
        <tr>
          <td>Summe Netto<br>20% MwSt.</td>
          <td :colspan="maxCols" class="text-right">{{ netto | currency }}<br>{{ tax | currency }}</td>
        </tr>
        <tr class="text-blue-dark">
          <td>Summe Brutto</td>
          <td :colspan="maxCols" class="text-right">{{ brutto | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Tooltip } from '../../components'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapGetters } = createNamespacedHelpers('basket/sponsorship')

export default {
  components: {
    Tooltip
  },
  props: {
    details: {
      default: false,
      type: [Boolean]
    },
    editable: {
      default: false,
      type: [Boolean]
    },
    headers: {
      default: true,
      type: [Boolean]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },
  computed: {
    ...mapGetters([
      'tax',
      'items',
      'netto',
      'brutto',
    ]),
    description () {
      return item => item.werbeabgabe
        ? `${item.description}  <small>(exkl. 5 % Werbeabgabe)</small>`
        : item.description
    },
    maxCols () {
      return this.editable ? 3 : 2
    },
  },

  methods: mapActions([
    'removeItem'
  ]),

  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagName,
    { tag: "component" },
    [
      _c(
        "transition",
        {
          attrs: {
            name: _vm.transition,
            "enter-active-class": _vm.enterActiveClass,
            "leave-active-class": _vm.leaveActiveClass,
          },
          on: { "after-leave": _vm.doDestroy },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.disabled && _vm.showPopper,
                  expression: "!disabled && showPopper",
                },
              ],
              ref: "popper",
              class: { visible: _vm.showPopper },
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.content))]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("reference"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const isFunction = obj => typeof obj === 'function' || false

export const findFactorsOf = num => {
  let factors = [];
  for (let index = 1; index < num; index++) {
    (num % index === 0) && factors.push(index);
    (index * index === num) && factors.push(index);
  }
  factors.push(num)

  return num == 1 ? [[1, 1]] : [...Array(factors.length / 2)].map(
    (x, index) => [factors[index], factors[factors.length - (index + 1)]]
  )
}

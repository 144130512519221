var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "step-five" }, [
    _vm.info
      ? _c("section", { staticClass: "info-section" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "infobox",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.info.subtitle))]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3688776340
                    ),
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.info.description) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "basket-section mt-30 mb-30" },
      [_c("order-summary")],
      1
    ),
    _vm._v(" "),
    _vm.availability
      ? _c("section", { staticClass: "sponsorship-check-section mb-30" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "infobox",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Fehler!")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      747931340
                    ),
                  },
                  [
                    _vm._v(" "),
                    _vm._l(_vm.availability, function (product) {
                      return _c("span", { key: product.id }, [
                        _c("strong", [_vm._v(_vm._s(product.description))]),
                        _vm._v(" ist bereits ausgebucht."),
                        _c("br"),
                      ])
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "highlight-section mt-70" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "highlight mb-30 text-justify" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "v-checkbox",
                    {
                      attrs: {
                        name: "privacy",
                        error: _vm.error("agreements.privacy"),
                        "error-message": "Diese Checkbox ist ein Pflichtfeld",
                      },
                      model: {
                        value: _vm.privacy,
                        callback: function ($$v) {
                          _vm.privacy = $$v
                        },
                        expression: "privacy",
                      },
                    },
                    [
                      _vm._v("\n                  Hiermit bestätige ich die "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.kleinwasserkraft.at/top/datenschutz/",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Datenschutzbestimmungen")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "v-checkbox",
                    {
                      attrs: {
                        name: "agb",
                        error: _vm.error("agreements.agb"),
                        "error-message": "Diese Checkbox ist ein Pflichtfeld",
                      },
                      model: {
                        value: _vm.agb,
                        callback: function ($$v) {
                          _vm.agb = $$v
                        },
                        expression: "agb",
                      },
                    },
                    [
                      _vm._v("\n                  Hiermit bestätige ich die "),
                      _c(
                        "a",
                        { attrs: { href: _vm.agb_link, target: "_blank" } },
                        [_vm._v("AGB")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "finish-checkout--blank text-right" },
      [
        _c(
          "a",
          {
            staticClass: "btn btn-dark",
            attrs: { href: "#", role: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.back.apply(null, arguments)
              },
            },
          },
          [_vm._v("Züruck")]
        ),
        _vm._v(" "),
        _vm.loading || _vm.checking
          ? _c(
              "a",
              {
                staticClass: "btn btn-dark",
                attrs: {
                  href: "#",
                  role: "button",
                  disabled: _vm.loading || _vm.checking,
                },
              },
              [_vm._v("Bitte warten")]
            )
          : _vm.loggedIn
          ? [
              _c(
                "a",
                {
                  staticClass: "btn btn-blue-dark",
                  attrs: { href: "#", role: "button", disabled: _vm.empty },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        Buchung aktualisieren\n      ")]
              ),
            ]
          : [
              _c(
                "a",
                {
                  staticClass: "btn btn-blue-dark",
                  attrs: { href: "#", role: "button", disabled: _vm.empty },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        Buchen\n      ")]
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
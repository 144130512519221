<template>
  <div id="app-root">
    <div class="container">
      <div class="row">
        <div class="col-10 offset-1">
          <navigation></navigation>
        </div>
      </div>
      <div class="row mt-50">
        <div class="col-10 offset-1">
            <div class="white-container">
              <router-view></router-view>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '../components/Navigation'

export default {
  components: {
    Navigation
  }
}
</script>


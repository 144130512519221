<template>
  <div v-if="visible" class="infobox" :class="classList">
    <div class="infobox-title"><slot name="title"></slot></div>
    <div class="infobox-content"><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      default: false
    }
  },
  computed: {
    visible () {
      return this.$slots.default || this.$slots.title
    },
    classList() {
      return {
        'infobox--icon': this.icon
      }
    }
  }
}
</script>

<template>
  <section class="step-one">
    <section class="info-section" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>
    <section class="form-section">
      <h2 class="section-heading">Firmendaten</h2>
      <div class="row">
        <div class="col-md-12">
          <v-input :tabindex="1" name="company" label="Firmenname: *" v-model="companyName" :error="error('company.name')" :error-message="error('company.name', true)" :disabled="hasCompany"></v-input>
        </div>
        <div class="col-md-6">
          <v-input :tabindex="2" name="street" label="Straße: *" v-model="companyStreet" :error="error('company.street')" :error-message="error('company.street', true)" :disabled="hasCompany"></v-input>
        </div>
        <div class="col-md-2">
          <v-input :tabindex="3" name="zip" label="PLZ: *" v-model="companyZip" :error="error('company.zip')" :error-message="error('company.zip', true)" :disabled="hasCompany"></v-input>
        </div>
        <div class="col-md-4">
          <v-input :tabindex="4" name="city" label="Ort: *" v-model="companyCity" :error="error('company.city')" :error-message="error('company.city', true)" :disabled="hasCompany"></v-input>
        </div>
        <div class="col-md-6">
          <v-select :tabindex="5" name="country" v-model="companyCountry" label="Land:" :options="countries" :error="error('company.country')" :error-message="error('company.country', true)" :disabled="hasCompany"></v-select>
        </div>
        <div class="col-md-6">
          <v-select :tabindex="6" name="member" v-model="companyMember" label="Mitglied*" :options="[{label: 'Ja', code: true},{label: 'Nein', code: false}]" :error="error('company.member')" :error-message="error('company.member', true)" :disabled="hasCompany"></v-select>
        </div>
      </div>
    </section>

    <section class="switch-section">
      <v-switch v-model="useInvoiceAddress" name="useInvoiceAddress" label="abweichende Rechnungsadresse"></v-switch>
      <div class="extraText">Anderenfalls wird die Rechnung an die obenstehenden Daten ausgestellt</div>
    </section>

    <section v-if="useInvoiceAddress" class="form-section">
      <h2 class="section-heading">Rechnungsadresse:</h2>
      <div class="row">
        <div class="col-md-6">
          <v-input :tabindex="9" name="company" v-model="invoiceName" label="Firmenname: *" :error="error('invoice.name')" :error-message="error('invoice.name', true)"></v-input>
          <v-input :tabindex="11" name="street" v-model="invoiceStreet" label="Straße: *" :error="error('invoice.street')" :error-message="error('invoice.street', true)"></v-input>
          <v-select :tabindex="13" name="country" v-model="invoiceCountry" label="Land:" :options="countries" :error="error('invoice.country')" :error-message="error('invoice.country', true)"></v-select>
        </div>
        <div class="col-md-6">
          <v-input :tabindex="10" name="zip" v-model="invoiceZip" label="PLZ: *" :error="error('invoice.zip')" :error-message="error('invoice.zip', true)"></v-input>
          <v-input :tabindex="12" name="city" v-model="invoiceCity" label="Ort: *" :error="error('invoice.city')" :error-message="error('invoice.city', true)"></v-input>
        </div>
      </div>
    </section>
    <section class="form-section">
      <h2 class="section-heading">KONTAKTPERSON</h2>
      <div class="row">
        <div class="col-md-6">
          <v-input :tabindex="14" name="title" v-model="contactTitle" label="Titel vorangestellt:" :disabled="loggedIn"></v-input>
          <v-input :tabindex="16" name="vorname" label="Vorname: *" v-model="contactFirstname" :error="error('contact.firstname')" :error-message="error('contact.firstname', true)" :disabled="loggedIn"></v-input>
          <v-input :tabindex="18" name="mail" label="E-Mail: *" v-model="contactEmail" :error="error('contact.email')" :error-message="error('contact.email', true)" :disabled="loggedIn"></v-input>
          <v-input :tabindex="20" name="phone" label="Telefon:" v-model="contactPhone" :disabled="loggedIn"></v-input>
        </div>
        <div class="col-md-6">
          <v-input :tabindex="15" name="title-after" label="Titel nachgestellt:" v-model="contactPostnominal" :disabled="loggedIn"></v-input>
          <v-input :tabindex="17" name="nachname" v-model="contactLastname" label="Nachname: *" :error="error('contact.lastname')" :error-message="error('contact.lastname', true)" :disabled="loggedIn"></v-input>
          <v-input :tabindex="19" name="mobile" v-model="contactMobile" label="Mobil:" :disabled="loggedIn"></v-input>
        </div>
      </div>
    </section>
    <section class="button-section text-right">
      <button class="btn btn-blue-dark" @click.prevent="next">weiter</button>
    </section>
  </section>
</template>

<script>
import { Infobox } from '../components'
import { VInput, VSelect, VSwitch } from '../components/Form'

import { mapFields } from 'vuex-map-fields'
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapBasketState,
  mapActions: mapBasketActions,
  mapGetters: mapBasketGetters,
} = createNamespacedHelpers('basket')

export default {
  components: {
    VInput,
    VSelect,
    VSwitch,
    Infobox,
  },

  data () {
    return {
      info: null,
      form: null
    }
  },

  computed: {
    ...mapDataState(['countries']),
    ...mapBasketGetters(['validate']),
    ...mapFields('basket/user', {
      loggedIn: 'loggedIn',
      hasCompany: 'hasCompany',
      useInvoiceAddress: 'useInvoiceAddress',

      companyUid: 'company.uid',
      companyZip: 'company.zip',
      companyName: 'company.name',
      companyCity: 'company.city',
      companyStreet: 'company.street',
      companyCountry: 'company.country',
      companySponsor: 'company.sponsor',
      companyContract: 'company.contract',
      companyMember: 'company.member',

      invoiceZip: 'invoice.zip',
      invoiceName: 'invoice.name',
      invoiceCity: 'invoice.city',
      invoiceStreet: 'invoice.street',
      invoiceCountry: 'invoice.country',

      contactSex: 'contact.sex',
      contactFax: 'contact.fax',
      contactPhone: 'contact.phone',
      contactTitle: 'contact.title',
      contactEmail: 'contact.email',
      contactMobile: 'contact.mobile',
      contactLastname: 'contact.lastname',
      contactFirstname: 'contact.firstname',
      contactPostnominal: 'contact.postnominal',
    }),
  },

  async created () {
    this.info = await this.infoForStep(1)
  },

  methods: {
    ...mapDataActions(['infoForStep']),

    error(field, returnMessage = false) {
      return this.form && this.form.errors.has(field)
        ? returnMessage && this.form.errors.get(field) || true
        : false
    },

    next() {
      // Regex for email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // Check if the email is valid
      if (!emailPattern.test(this.contactEmail)) {
        alert('Ungültige E-Mail-Adresse');
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.$router.push({ name: 'step-2' })
      }
    },
  },

  async beforeRouteLeave (to, from, next) {
    this.form = await this.validate('user')
    if (this.form.errors.any() === false) {
      next()
    }
  }
}
</script>


<template>
  <div class="checkout-cart" v-if="item">
    <table class="text-medium">
      <thead>
        <tr>
          <td colspan="3" class="title w-auto">
            SPONSORPAKET:
            <tooltip
              v-if="tooltip"
              trigger="hover"
              :options="{ placement: 'right-start' }">
              <div class="tooltip">
                <div class="tooltip-inner" v-html="tooltip"></div>
              </div>
              <span class="info-circle-icon" slot="reference"></span>
            </tooltip>
          </td>
          <td class="w-50" v-if="editable"></td>
        </tr>
      </thead>
      <tbody>
        <tr class="w-auto pr-40 has-separators" :class="{ 'has-separators': editable }" v-if="details">
          <td>
            {{ description }}
          </td>
          <td class="w-50 text-center text-bold">1</td>
          <td class="w-150 text-right text-bold pl-20" :class="{ 'text-right': !editable, 'text-center': editable }">
            {{ item.total | currency }}
          </td>
          <td class="pl-20" v-if="editable"><span class="trash-icon" @click.prevent="clear()"></span></td>
        </tr>
        <tr>
          <td :colspan="editable ? 3 : 2">
            <template v-if="details">
              Teilnehmer*in: {{ item.participants }} Personen
              <template v-if="exhibit">
                | Messestand: {{ exhibit.nr }} - {{ exhibit.description }}<br>
              </template>
              <template v-else-if="item.type == 4">
                | Messestand: Online<br>
              </template>
              <template v-else-if="item.type != 1">
                | Messestand: <span class="text-red-light">kein Messestand ausgewählt</span><br>
              </template>
            </template>
            Summe Netto<br>
            20% MwSt.
          </td>
          <td class="text-right">
            <template v-if="details">
              <br>
            </template>
            {{ netto | currency }}<br>
            {{ tax | currency }}
          </td>
        </tr>
        <tr class="text-blue-dark text-bold">
          <td :colspan="editable ? 3 : 2">Summe Brutto</td>
          <td class="text-right">{{ brutto | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Tooltip } from '../../components'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('basket/sponsorpackage')
const { mapGetters: mapExhibitGetters } = createNamespacedHelpers('basket/exhibitionBooth')
const { mapGetters: mapUserGetters } = createNamespacedHelpers('basket/user')


export default {
  components: {
    Tooltip
  },
  props: {
    details: {
      default: false,
      type: [Boolean]
    },
    editable: {
      default: false,
      type: [Boolean]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },
  computed: {
    ...mapExhibitGetters({
      exhibit: 'item'
    }),
    ...mapUserGetters({
      userIsMember: 'isMember'
    }),
    ...mapGetters([
      'tax',
      'item',
      'netto',
      'brutto',
    ]),
    description() {
      return this.userIsMember
        ? this.item.prices['member'].description
        : this.item.prices['non-member'].description
    }
  },
  methods: mapActions([
    'clear'
  ]),
  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.groupClassList },
    [
      _c("v-label", {
        attrs: { name: _vm.name, label: _vm.label, tooltip: _vm.tooltip },
      }),
      _vm._v(" "),
      _c(
        "v-select",
        {
          attrs: {
            id: _vm.name,
            tabindex: _vm.tabindex,
            clearable: false,
            options: _vm.options,
            disabled: _vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _c("span", { domProps: { innerHTML: _vm._s(option.label) } }),
                ]
              },
            },
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("span", { domProps: { innerHTML: _vm._s(option.label) } }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.internalValue,
            callback: function ($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue",
          },
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "no-options" }, [
            _vm._v("\n      " + _vm._s(_vm.noOptions) + "\n    "),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-error", { attrs: { message: _vm.errorMessage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="groupClassList">
    <v-label :name="name" :label="label" :tooltip="tooltip"></v-label>
    <div class="input-group spinner">
      <input
        type="text"
        class="form-control"
        id="user-area"
        :value="numericValue"
        @input="inputValue"
        @keypress="validateInput"
      />
      <div class="input-group-append">
        <span class="input-group-text" v-if="unit" v-html="unit"></span>
        <span class="input-group-text buttons">
          <a class="spinner-button spinner-button-up"
            @click.prevent="increaseNumber"
            @mousedown="delayCall(250, () => whileMouseDown(increaseNumber))"
            @mouseup="clearTimer">+</a>
          <a class="spinner-button spinner-button-down"
            @click.prevent="decreaseNumber"
            @mousedown="delayCall(250, () => whileMouseDown(decreaseNumber))"
            @mouseup="clearTimer">-</a>
        </span>
      </div>
    </div>
    <v-error :message="errorMessage"></v-error>
  </div>
</template>

<script>
import { VLabel, VError } from '../Form'

export default {
  components: {
    VLabel,
    VError
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 9999,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    },
    mouseDownSpeed: {
      default: 100,
      type: Number
    },
    integerOnly: {
      default: false,
      type: Boolean
    },
    unit: {
      defaul: null,
      type: String
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    error: {
      default: null,
      type: [Boolean]
    },
    errorMessage: {
      default: null,
      type: [String]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },
  data () {
    return {
      lazyValue: this.value,
      timer: null,
      delay: null
    };
  },

  computed: {
    groupClassList () {
      return {
        'form-group': true,
        'has-error': this.error,
        'is-filled': this.numericValue !== 0
      }
    },
    numericValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.setValue(val, true)
      }
    }
  },

  methods: {
    increaseNumber() {
      this.numericValue = Math.round((this.numericValue + this.step) * 100) / 100;
    },
    decreaseNumber() {
      this.numericValue = Math.round((this.numericValue - this.step) * 100) / 100;
    },

    inputValue(evt) {
      this.numericValue = evt.target.value
        ? parseInt(evt.target.value)
        : this.min;
    },

    validateInput(evt) {
      if (this.integerOnly === true) {
        this.isInteger(evt);
      } else {
        this.isNumber(evt);
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      let key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
      const regex = /[0-9]/;
      if (!regex.test(key)) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault();
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    delayCall(ms, callback) {
      if (this.delay === null) {
        this.delay = setTimeout(() => callback(), ms)
      }
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (this.delay) {
        clearTimeout(this.delay);
        this.delay = null;
      }
    },
    whileMouseDown(callback) {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          callback();
        }, this.mouseDownSpeed);
      }
    },
    setValue(val, internal) {
      // - validate input
      if (val <= this.min) {
        this.lazyValue = parseInt(this.min);
      }
      if (val >= this.max) {
        this.lazyValue = parseInt(this.max);
      }

      if (val <= this.max && val >= this.min) {
        // - update value
        const oldVal = this.lazyValue;
        this.lazyValue = val;

        // - Notify only on internal change
        internal && this.$emit('input', val, oldVal);
      }
    },
  },

  watch: {
    value: function (val) {
      this.setValue(val)
    }
  },
}
</script>


import Vue          from 'vue'
import EventEmitter from '@Utils/event-emitter'

class ApplicationKernel extends EventEmitter {
  constructor (config) {
    super()
    this.config = config
  }

  init (config) {
    if (!document.getElementById(this.config.el.replace('#', ''))) {
      return false
    }

    this.config = Object.assign({}, config || {}, this.config)

    const { type } = this.config;
    const { App, Store, Router } = require(`./${type}-form`).default;

    const { el } = this.config
    const store  = new Store(this.config)
    const router = new Router()

    this.vm = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount(el)
  }
}

export default ApplicationKernel

import VLabel from './VLabel.vue'
import VError from './VError.vue'
import VInput from './VInput.vue'
import VSelect from './VSelect.vue'
import VSwitch from './VSwitch.vue'
import VSpinner from './VSpinner.vue'
import VCheckbox from './VCheckbox.vue'
import VRadio from './VRadio.vue'
import VTextarea from './VTextarea.vue'

export {
  VLabel,
  VError,
  VInput,
  VSelect,
  VSwitch,
  VSpinner,
  VCheckbox,
  VRadio,
  VTextarea,
}

export default {
  VLabel,
  VError,
  VInput,
  VSelect,
  VSwitch,
  VSpinner,
  VCheckbox,
  VRadio,
  VTextarea,
}

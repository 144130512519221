<template>
  <section class="checkout-section">
    <h2 class="section-heading"><span class="shopping-cart-icon mr-10"></span>Warenkorb</h2>
    <div class="checkout-cart mt-70">
      <table class="headers">
        <thead>
          <tr class="text-blue-dark">
            <td class="w-auto">Produkte</td>
            <td class="w-50">Menge</td>
            <td class="w-150 text-center">Preis</td>
          </tr>
        </thead>
      </table>
    </div>
    <section class="summary-section mt-30 mb-30">
      <participant-summary :details="true" :headers="false"></participant-summary>
    </section>
    <section v-if="brutto === 0" class="summary-section cartEmpty mb-30">
      Ihr Warenkorb ist leer
    </section>
    <section class="checkout-cart summary-section">
      <table class="headers">
        <thead>
          <tr class="text-blue-dark bt-none bb-dark">
            <td class="w-auto">Gesamtsumme</td>
            <td class="w-150 text-right">{{ brutto | currency }}</td>
          </tr>
        </thead>
      </table>
    </section>
  </section>
</template>

<script>
import ParticipantSummary from './ParticipantSummary'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('basket')

export default {
  components: {
    ParticipantSummary,
  },
  computed: mapGetters([
      'brutto',
  ]),
  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

import { getField, updateField } from 'vuex-map-fields'

const sponsorpackage = {
  namespaced: true
}

// STATE
sponsorpackage.state = {
  selected: null
}

// MUTATIONS
sponsorpackage.mutations = {
  updateField,
  clear(state) {
    state.selected = null
  },
}

// ACTIONS
sponsorpackage.actions = {
  clear({ commit }) {
    commit('clear')
  },
}

// GETTERS
sponsorpackage.getters = {
  getField,
  item: ({ selected }, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';
    return selected ? {...selected, total: selected.prices[priceGroup].price } : null
  },
  tax: (state, getters) => {
    return getters.brutto - getters.netto
  },
  netto: (state, getters) => {
    return getters.item.total;
  },
  brutto: (state, getters, rootState, rootGetters) => {
    const priceGroup = rootGetters['basket/user/isMember'] ? 'member' : 'non-member';
    const price = state.selected.prices[priceGroup];

    return Math.round(
      getters.item.total * (1 + price.tax / 100) * 100
    ) / 100
  },
}

export default sponsorpackage;

import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=507d9fe6"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"
import style0 from "./Table.vue?vue&type=style&index=0&id=507d9fe6&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kiliantrunk/KoerblerProjects/kleinwasserkraft-shop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('507d9fe6')) {
      api.createRecord('507d9fe6', component.options)
    } else {
      api.reload('507d9fe6', component.options)
    }
    module.hot.accept("./Table.vue?vue&type=template&id=507d9fe6", function () {
      api.rerender('507d9fe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/app/participant-form/components/CheckboxTable/Table.vue"
export default component.exports
<template>
  <section class="step-four">
    <section class="sponsoring-tables-section">
      <h2 class="section-heading">Teilnehmer*in</h2>
      <p class="mb-0">Aufgrund Ihrer Auswahl können Sie bis zu {{ selectedSponsorPackage ? selectedSponsorPackage.participants : 0 }} Teilnehmer*in anmelden.</p>
      <span>*Pflichtfelder</span>
      <br>
      <infobox>
        Die Anmeldung  können Sie mit „Weiter“ auf einen anderen Termin verschieben. Wir benachrichtigen Sie zu gegebenen Zeit um die Anmeldung auszufüllen.
      </infobox>
      <div class="text-right">
        <button class="btn btn-blue-dark" @click.prevent="next">weiter</button>
      </div>
      <div class="line-spacer-blue"></div>

      <participants :max="selectedSponsorPackage ? selectedSponsorPackage.participants : 0"></participants>

      <div class="row">
        <div class="col-md-12" v-if="availability">
          <infobox>
            <template v-slot:title>Fehler!</template>
            <span v-for="product in availability" :key="product.id">
              <strong>{{ product.description }}</strong> ist bereits ausgebucht.<br>
            </span>
          </infobox>
        </div>
      </div>
    </section>
    <section class="info-section mt-30" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>
    <section class="summary-section mt-30 mb-30" v-if="selectedSponsorPackage !== null || selectedSpecialItems.length">
      <h2 class="section-heading"><span class="shopping-cart-icon mr-10"></span>Warenkorb</h2>
      <sponsorship-summary :details="true" :editable="true"></sponsorship-summary>
      <exhibit-summary :details="false"></exhibit-summary>
      <special-summary :details="false" :editable="false" :headers="false"></special-summary>
    </section>
    <section class="button-section text-right">
      <!-- <button v-if="sponsorshipItems.length === 0" class="btn btn-dark" @click.prevent="next">überspringen</button> -->
      <!-- <button v-else-if="loading" class="btn btn-dark" @click.prevent disabled>Bitte warten</button> -->
      <button class="btn btn-blue-light mr-1" @click.prevent="skip('back')">Zurück</button>
      <button class="btn btn-blue-dark" @click.prevent="next">Weiter</button>
    </section>
  </section>
</template>

<script>
import { SponsorshipSummary, SpecialSummary, ExhibitSummary } from '../components/Checkout'
import { Infobox, OrderTable } from '../components'
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Participants from '../components/Elements/Participants'


const {
  mapActions: mapBasketActions
} = createNamespacedHelpers('basket')

const {
  mapGetters: mapExhibitGetters,
} = createNamespacedHelpers('basket/exhibit')

const {
  mapGetters: mapSymposiumGetters,
} = createNamespacedHelpers('basket/symposium')

const {
  mapActions: mapSponsorshipActions,
  mapGetters: mapSponsorshipGetters,
} = createNamespacedHelpers('basket/sponsorship')

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapState: mapSponsorState,
} = createNamespacedHelpers('basket/sponsorpackage')

const {
  mapState: mapSpecialState,
} = createNamespacedHelpers('basket/specialpackage')


export default {
  components: {
    Infobox,
    OrderTable,
    SponsorshipSummary,
    SpecialSummary,
    ExhibitSummary,
    Participants,
  },

  data () {
    return {
      info: null,
      loading: false,
      availability: null,
    }
  },

  computed: {
    ...mapDataState(['packages']),
    ...mapExhibitGetters({ exhibitItems: 'items' }),
    ...mapSymposiumGetters({ symposiumItems: 'items' }),
    ...mapSponsorshipGetters({ sponsorshipItems: 'items' }),
    ...mapSpecialState({ selectedSpecialItems: 'selected' }),
    ...mapSponsorState({ selectedSponsorPackage: 'selected' }),
    ...mapFields('basket/sponsorship', {
      selectedOther: 'selected.other',
    }),

    advertisements() {
      return this.sponsorship.advertisements.map(item => {
        return {
          ...item,
          description: item.exclusive
            ? `<span class="text-red-light">exklusiv: </span>${item.description}`
            : item.description
        };
      })
    },
    other() {
      return this.sponsorship.other.map(item => {
        return {
          ...item,
          description: item.exclusive
            ? `<span class="text-red-light">exklusiv: </span>${item.description}`
            : item.description
        };
      })
    },
  },

  async created () {
    this.info = await this.infoForStep(4)
  },

  methods: {
    ...mapDataActions(['infoForStep']),
    ...mapSponsorshipActions(['checkAvailability']),

    skip (fn = 'next') {
      this.skipValidation = true
      this.participants = []
      this[fn]()
    },
    next() {
      this.$router.push({ name: 'step-4' })
    },
    back() {
      this.$router.push({ name: 'step-2' })
    },
  },

  async beforeRouteLeave (to, from, next) {
    this.loading = true;
    const response = await this.checkAvailability()
    this.loading = false;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.availability = response
    response === false && next()
  }
}
</script>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.groupClassList }, [
    _c("label", { staticClass: "radio" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalValue,
            expression: "internalValue",
          },
        ],
        attrs: { type: "radio", name: _vm.name, id: _vm.id },
        domProps: { value: _vm.id, checked: _vm._q(_vm.internalValue, _vm.id) },
        on: {
          change: function ($event) {
            _vm.internalValue = _vm.id
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox-box" }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "checkbox-label text-regular" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.tooltip
            ? _c(
                "tooltip",
                {
                  attrs: {
                    trigger: "hover",
                    options: { placement: "right-start" },
                  },
                },
                [
                  _c("div", { staticClass: "tooltip" }, [
                    _c("div", {
                      staticClass: "tooltip-inner",
                      domProps: { innerHTML: _vm._s(_vm.tooltip) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "info-circle-icon",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
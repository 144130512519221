<template>
  <div class="checkout-cart" v-if="items && items.length">
    <table>
      <thead>
        <tr>
          <td class="title w-auto">
            PAKETE
            <tooltip
              v-if="tooltip"
              trigger="hover"
              :options="{ placement: 'right-start' }">
              <div class="tooltip">
                <div class="tooltip-inner" v-html="tooltip"></div>
              </div>
              <span class="info-circle-icon" slot="reference"></span>
            </tooltip>
          </td>
          <td class="w-50"><template v-if="headers">Menge</template></td>
          <td class="w-150 text-right" :class="{ 'pr-20': editable }"><template v-if="headers">Preis</template></td>
          <td class="w-50" v-if="editable"></td>
        </tr>
      </thead>
      <tbody>
        <template v-if="details">
          <tr class="has-separators" v-for="(item, key) in items" :key="key">
            <td class="w-auto pr-40">
              <b>{{ item.description }}</b><br>
            </td>
            <td class="w-50 text-center text-bold ">{{ item.qty }}</td>
            <td class="w-150 text-right text-bold pl-20" :class="{ 'pr-20': editable }">{{ item.price | currency }}</td>
            <td class="pl-20" v-if="editable"><span class="trash-icon" @click.prevent="removeItem(item.id)"></span></td>
          </tr>
        </template>
        <tr class="text-bold">
          <td>Summe Netto<br>20% MwSt.</td>
          <td :colspan="maxCols" class="text-right">{{ netto | currency }}<br>{{ tax | currency }}</td>
        </tr>
        <tr class="text-bold text-blue-dark">
          <td>Summe Brutto</td>
          <td :colspan="maxCols" class="text-right">{{ brutto | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Tooltip } from '../../components'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapGetters } = createNamespacedHelpers('basket/participantpackage')

const {
  mapGetters: mapUserGetters,
} = createNamespacedHelpers('basket/user')

export default {
  components: {
    Tooltip
  },
  props: {
    details: {
      default: false,
      type: [Boolean]
    },
    editable: {
      default: false,
      type: [Boolean]
    },
    headers: {
      default: true,
      type: [Boolean]
    },
    tooltip: {
      default: null,
      type: [String]
    }
  },
  computed: {
    ...mapUserGetters(['isMember']),
    ...mapGetters([
      'tax',
      'items',
      'netto',
      'brutto',
    ]),
    maxCols () {
      return this.editable ? 3 : 2
    },
    priceGroup() {
      return this.isMember ? 'member' : 'non-member';
    },
  },
  methods: mapActions([
    'removeItem'
  ]),
  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "step-three" },
    [
      _vm.errors.missing
        ? _c(
            "infobox",
            {
              staticClass: "infobox--alert",
              attrs: { icon: true },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v("Fehler!")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                747931340
              ),
            },
            [
              _vm._v(
                "\n    Sie müssen einen Sponsorpaket oder Spezialpaket ausählen.\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("sponsor-packages"),
      _vm._v(" "),
      _c("div", { staticClass: "pt-5 pb-5" }),
      _vm._v(" "),
      _vm.loggedIn
        ? _c("special-summary", {
            attrs: { details: true, totals: false, headers: true },
          })
        : _c("special-packages"),
      _vm._v(" "),
      _c("div", { staticClass: "pt-5 pb-5" }),
      _vm._v(" "),
      _c("exhibition-booths", { attrs: { error: _vm.errors.exhibit } }),
      _vm._v(" "),
      _vm.info
        ? _c("section", { staticClass: "info-section" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "infobox",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.info.subtitle))]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3688776340
                      ),
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.description) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedSponsorPackage !== null || _vm.selectedSpecialItems.length
        ? _c(
            "section",
            { staticClass: "summary-section mt-30 mb-30" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("exhibit-summary", { attrs: { details: true } }),
              _vm._v(" "),
              _c("special-summary", {
                attrs: { details: true, editable: !_vm.loggedIn },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "button-section text-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-blue-light mr-1",
            attrs: {
              href: "/uploads/KWK_Jahrestagung_Sponsoreninformation.pdf",
              target: "_blank",
            },
          },
          [_vm._v("Sponsoreninformation")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-blue-light mr-1",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.skip("back")
              },
            },
          },
          [_vm._v("Zurück")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-blue-dark",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.next.apply(null, arguments)
              },
            },
          },
          [_vm._v("Weiter")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "section-heading" }, [
      _c("span", { staticClass: "shopping-cart-icon mr-10" }),
      _vm._v("Warenkorb"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { isFunction } from '.'

export default class EventEmitter {
  constructor () {
    this.listeners = new Map()
  }

  addEventListener (name, callback) {
    this.listeners.has(name) || this.listeners.set(name, [])
    this.listeners.get(name).push(callback)
  }

  removeListener (name, callback) {
    let listeners = this.listeners.get(name)
    let index

    if (listeners && listeners.length) {
      index = listeners.reduce((previous, listener, index) => {
        let isCallback = (isFunction(listener) && listener === callback)
        previous = isCallback ? index : previous
        return previous
      }, -1)

      if (index > -1) {
        listeners.splice(index, 1)
        this.listeners.set(name, listeners)
        return true
      }
    }
    return false
  }

  dispatchEvent (name, data) {
    let listeners = this.listeners.get(name)
    let event = { type: name, data }

    if (listeners && listeners.length) {
      listeners.forEach((listener) => {
        typeof listener === 'function'
          ? listener(event)
          : (listener.handleEvent && typeof listener.handleEvent === 'function' && listener.handleEvent(event))
      })

      return true
    }

    return false
  }

  removeAllListeners (name) {
    name ? this.listeners.set(name, []) : this.listeners.clear()
  }
}

import Views from '../views'

// 1: 'Firmendaten & Sponsor',
// 2: 'Pakete & Messestand',
// 3: 'Teilnehmer*in & weitere Personen',
// 4: 'Bestellung abschließen',

export default [
  {
    name: 'index',
    path: '/',
    component: Views.StepOne,
    meta: {
      title: 'Teilnehmer*in Registrierung',
    }
  },

  // {
  //   name: 'step-depricated',
  //   path: '/teilnehmer-buchen/ausstellungflaechen',
  //   component: Views.StepTwo,
  //   meta: {
  //     title: 'testor',
  //   }
  // },

  // {
  //   name: 'step-2',
  //   path: '/teilnehmer-buchen/pakete-und-messestand',
  //   component: Views.StepThree,
  //   meta: {
  //     title: 'Pakete & Messestand',
  //   }
  // },

  // {
  //   name: 'step-3',
  //   path: '/teilnehmer-buchen/teilnehmer',
  //   component: Views.StepFour,
  //   meta: {
  //     title: 'Teilnehmer*in & weitere Personen',
  //   }
  // },

  {
    name: 'step-2',
    path: '/abschliessen',
    component: Views.StepFive,
    meta: {
      title: 'Bestellung abschließen',
    }
  },

  {
    name: 'thankyou',
    path: '/thankyou',
    component: Views.Thankyou,
    meta: {
      title: 'Vielen Dank!',
    }
  },
];

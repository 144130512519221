import { render, staticRenderFns } from "./SponsorshipSummary.vue?vue&type=template&id=3865dff5"
import script from "./SponsorshipSummary.vue?vue&type=script&lang=js"
export * from "./SponsorshipSummary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kiliantrunk/KoerblerProjects/kleinwasserkraft-shop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3865dff5')) {
      api.createRecord('3865dff5', component.options)
    } else {
      api.reload('3865dff5', component.options)
    }
    module.hot.accept("./SponsorshipSummary.vue?vue&type=template&id=3865dff5", function () {
      api.rerender('3865dff5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/app/participant-form/components/Checkout/SponsorshipSummary.vue"
export default component.exports
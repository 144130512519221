<template>
<perfect-scrollbar :class="{'ps-online': online}">
<div class="checkbox-table" style="padding-right: 1px">
    <table>
      <thead v-if="headers">
        <tr>
          <td v-for="(header, key) in headers" :key="key" :class="header.class">{{ header.title }}</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in internalItems">
          <row v-if="(online && item.online) || (!online)" :key="item.id" @change="updateSelected" :data="item" :checked="item.checked" :disabled="item.disabled || disabled"></row>
        </template>
      </tbody>
    </table>
</div>
</perfect-scrollbar>
</template>

<script>
import Vue from 'vue'
import Row from './Row'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

export default {
  components: {
    Row
  },
  props: {
    headers: {
      default: null,
      type: [Array]
    },
    items: {
      default: null,
      type: [Array]
    },
    value: {
      default: () => [],
      type: [Array]
    },
    single: {
      default: false,
      type: [Boolean]
    },
    online: {
      default: false,
      type: [Boolean]
    },
    disabled: {
      default: false,
      type: [Boolean]
    },
  },

  data() {
    return {
      internalItems: this.items,
      lazySelected: this.value,
    }
  },

  computed: {
    internalSelected: {
      get() {
        return this.lazySelected
      },
      set(val) {
        this.lazySelected = val;
        this.$emit('input', val);
      }
    },
  },

  methods: {
    updateSelected(item) {
      if (this.single) {
        this.internalSelected = item.checked ? [item] : null
        this.internalItems = this.internalItems.map(i => ({...i, checked: i.id === item.id}))
      } else {
        let selected = [...this.lazySelected]
        let insertedItem = selected.filter(i => i.id === item.id).pop()

        if (insertedItem) {
          let index = selected.indexOf(insertedItem)
          item.quantity > 0
            ? selected[index] = item
            : selected.splice(index, 1)
        } else {
          item.quantity > 0 && selected.push(item)
        }

        this.internalSelected = selected
      }
    }
  },
  watch: {
    value (val) {
      this.lazySelected = val

      val.forEach(item => {
        this.internalItems = this.internalItems.map(i => ({...i, checked: i.id === item.id}))
      });
    },
    items: function (items) {
      this.internalItems = items
    },
  }
}
</script>

<style lang="scss">
.ps {
  height: 360px;

  &.ps-online {
    height: 150px;
  }

  .ps__rail-y {
    width: 10px;
    background-color: #D8D8D8;
    transition: background-color .2s linear, width .2s ease-in-out;
    &:hover,
    &.ps--clicking {
      width: 15px;
    }
    .ps__thumb-y {
      background-color: #1750B3;
    }
  }

  &:hover > .ps__rail-y {
    opacity: 1;
  }
}

</style>

import StepOne   from './StepOne.vue'
import StepTwo   from './StepTwo.vue'
import StepThree from './StepThree.vue'
import StepFour  from './StepFour.vue'
import StepFive  from './StepFive.vue'
import Thankyou from './Thankyou.vue'

export default {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  Thankyou,
}

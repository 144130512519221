var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "input-spinner-group" }, [
    _c(
      "a",
      {
        staticClass: "spinner-button spinner-button-up",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.increaseNumber.apply(null, arguments)
          },
        },
      },
      [_vm._v("+")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "spinner-button spinner-button-down",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.decreaseNumber.apply(null, arguments)
          },
        },
      },
      [_vm._v("-")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentRoute !== "thankyou"
    ? _c("div", { staticClass: "nav-container" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("nav", { attrs: { id: "steps-navigation" } }, [
          _c("ul", { staticClass: "nav nav-pills nav-fill" }, [
            _c(
              "li",
              {
                staticClass: "nav-item",
                class: [
                  _vm.activeSteps[_vm.currentRoute] &&
                  _vm.activeSteps[_vm.currentRoute].includes("index")
                    ? "active"
                    : "",
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-drop",
                    attrs: { to: { name: "index" }, tag: "span" },
                  },
                  [_c("span", { staticClass: "nav-circle" }, [_vm._v("1")])]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "nav-item",
                class: [
                  _vm.activeSteps[_vm.currentRoute] &&
                  _vm.activeSteps[_vm.currentRoute].includes("step-2")
                    ? "active"
                    : "",
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-drop",
                    attrs: { to: { name: "step-2" }, tag: "span" },
                  },
                  [_c("span", { staticClass: "nav-circle" }, [_vm._v("2")])]
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="slot-booking-section bb-dark mb-30">
    <div class="row">
      <div class="col-md-12">
        <order-table title="Spezialpakete" :items="items" v-model="selectedPackages"></order-table>
      </div>
    </div>
  </section>
</template>

<script>
import { OrderTable } from '../../components'
import { mapFields } from 'vuex-map-fields'
import { createNamespacedHelpers } from 'vuex'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

const {
  mapGetters: mapUserGetters,
} = createNamespacedHelpers('basket/user')

export default {
  components: {
    OrderTable
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapDataState(['packages']),
    ...mapUserGetters(['isMember']),
    ...mapFields('basket/specialpackage', {
      selectedPackages: 'selected'
    }),
    priceGroup() {
      return this.isMember ? 'member' : 'non-member';
    },
    items () {
      return this.packages.special.map(item => ({
        ...item,
        price: item.prices[this.priceGroup].price,
        description: item.prices[this.priceGroup].description,
      }))
    }
  }
}
</script>

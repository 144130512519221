<template>
  <section class="step-two">
    <section class="form-section mb-30">
      <h2 class="section-heading">Buchung Ausstellungsfläche:</h2>
      <template v-if="isProductAvailable">
        <p>
          Mindestausstellungsfläche: 6 m² zu {{ flatrate.price | currency }} | pro weiterem Quadratmeter = {{ additional.price | currency }}
          <br>
          <small>Preise exklusive Stromanschluss-/verbrauch, Mobiliar, Trennwände, Standreinigung, odgl.</small>
        </p>
        <div class="row">
          <div class="col-md-4">
            <v-spinner name="bodenflaeche" @input="updateDimensions" v-model="area" label="Bodenfläche: " :min="minArea" :step="1" tooltip="Die georderten Maße gelten als Außenmaße." unit="m<sup>2</sup>"></v-spinner>
          </div>
          <div class="col-md-4">
            <v-spinner name="length" @input="updateArea" v-model="length" :min="minLength" label="Länge: " tooltip="Ihre Wunschmaße können hier eingegeben/verändert werden" unit="m"></v-spinner>
          </div>
          <div class="col-md-4">
            <v-spinner name="depth" @input="updateArea" v-model="depth" :min="minDepth" label="Tiefe: " tooltip="Ihre Wunschmaße können hier eingegeben/verändert werden" unit="m"></v-spinner>
          </div>
        </div>
      </template>
    </section>
    <template v-if="isProductAvailable">
      <section class="summary-section">
        <exhibit-summary :details="true" :editable="true"></exhibit-summary>
      </section>
      <section class="form-section mt-70">
        <h2 class="section-heading">Zusatzinformation:</h2>
        <div class="row">
          <div class="col-md-6">
            <v-textarea name="anmerkung" v-model="remarks" label="Besondere Anmerkungen:">
              Bitte geben Sie an, ob Ihr Stand keinesfalls neben dem eines Mitbewerbers stehen soll od. ob eine spezielle Konstruktion, etc. geplant ist
            </v-textarea>
          </div>
          <div class="col-md-6 pt-25">
            <v-radio v-model="option" name="exhibit-option" id="own-build">Wir bauen einen Eigenstand auf.</v-radio>
            <v-radio v-model="option" name="exhibit-option" id="own-rollups">Wir verwenden einen Faltstand/Roll-Up(s).</v-radio>
            <v-radio v-model="option" name="exhibit-option" id="own-stand">Wir werden einen Standard-Systemstand gegen Extragebühren bestellen.</v-radio>
          </div>
        </div>
      </section>
    </template>
    <section class="info-section" v-if="info">
      <div class="row">
        <div class="col-md-12">
          <infobox>
            <template v-slot:title>{{ info.subtitle }}</template>
            {{ info.description }}
          </infobox>
        </div>
      </div>
    </section>
    <section class="button-section text-right">
      <button v-if="area == 0" class="btn btn-dark" @click.prevent="next">überspringen</button>
      <button v-else class="btn btn-blue-dark" @click.prevent="next">weiter</button>
    </section>
  </section>
</template>

<script>
import { Infobox } from '../components'
import { findFactorsOf } from '@Utils'
import { ExhibitSummary } from '../components/Checkout'
import { VSpinner, VRadio, VTextarea } from '../components/Form'
import { createNamespacedHelpers } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const {
  mapState: mapDataState,
  mapActions: mapDataActions,
} = createNamespacedHelpers('data')

export default {
  components: {
    Infobox,
    VSpinner,
    VRadio,
    VTextarea,
    ExhibitSummary,
  },

  data () {
    return {
      info: null
    }
  },

  computed: {
    ...mapDataState(['exhibit']),

    ...mapFields('basket/exhibit', [
      'area',
      'depth',
      'length',
      'option',
      'remarks'
    ]),

    flatrate () {
      return this.exhibit.flatrate
    },

    additional () {
      return this.exhibit.additional
    },

    minDepth () {
      let depth = this.depth;
      let minDepth = depth;
      while (depth >= 0) {
        const area = depth * this.length;
        if (area < 6) {
          break;
        }
        minDepth = depth;
        depth--;
      }
      return minDepth;
    },

    minLength () {
      let length = this.length;
      let minLength = length;
      while (length >= 0) {
        const area = this.depth * length;
        if (area < 6) {
          break;
        }
        minLength = length;
        length--;
      }
      return minLength;
    },

    minArea () {
      return this.area > 0 ? 6 : 0;
    },

    isProductAvailable() {
      return this.flatrate.qty > 0 && this.additional.qty > 0
    }
  },

  async created () {
    this.info = await this.infoForStep(2)
  },

  methods: {
    ...mapDataActions(['infoForStep']),

    updateArea () {
      let area = this.length * this.depth;
      if (area >= 6) {
        this.area = area
      }
    },

    updateDimensions () {
      if (this.area > 0 && this.area < 6) {
        this.area = 6;
      }

      if (this.area > 0) {
        let [depth, length] = findFactorsOf(this.area).pop()
        this.depth = depth;
        this.length = length;
      } else {
        this.depth = 0;
        this.length = 0;
      }
    },

    next () {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      this.$router.push({ name: 'step-3' })
    }
  },

  filters: {
    currency(val) {
      return val.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
  }

}
</script>


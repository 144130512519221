<template>
  <div>
    <div class="participant">
      <div class="row">
        <div class="col-md-6">
          <v-input
            v-model="participant.title"
            name="titel"
            label="Titel">
          </v-input>
        </div>
        <div class="col-md-6">
          <v-input
            v-model="participant.postnominal"
            name="titel-after"
            label="Titel nachgestellt">
          </v-input>
        </div>
        <div class="col-md-6">
          <v-input
            :error="error(`participants.${index}.firstname`)"
            :error-message="error(`participants.${index}.firstname`, true)"
            v-model="participant.firstname"
            name="firstname"
            label="Vorname*">
          </v-input>
        </div>
        <div class="col-md-6">
          <v-input
            :error="error(`participants.${index}.lastname`)"
            :error-message="error(`participants.${index}.lastname`, true)"
            v-model="participant.lastname"
            name="lastname"
            label="Nachname*">
          </v-input>
        </div>
        <div class="col-md-6">
          <v-input
            v-model="participant.phone"
            name="phone"
            label="Telefon">
          </v-input>
        </div>
        <div class="col-md-6">
          <v-input
            :error="error(`participants.${index}.email`)"
            :error-message="error(`participants.${index}.email`, true)"
            v-model="participant.email"
            name="email"
            label="E-Mail*">
          </v-input>
        </div>
        <div class="col-md-6" v-if="!online">
          <v-select
            :error="error(`participants.${index}.eveningEvent`)"
            :error-message="error(`participants.${index}.eveningEvent`, true)"
            v-model="participant.eveningEvent"
            name="abendveranstaltung"
            label="Abendveranstaltung*"
            :options="[{label: 'Ja', code:'true'},{label: 'Nein', code:'false'}]">
          </v-select>
        </div>
      </div>
    </div>

    <section class="form-section">
      <p class="m-0">Programmpakete*</p>
      <div class="form-group has-error" v-if="error(`participants.${index}.package`)">
        <div class="invalid-tooltip">{{ error(`participants.${index}.package`, true) }}</div>
      </div>
      <checkbox-table class="mt-5" :online="online" :items="items" :single="true" v-model="participantPackage"></checkbox-table>
    </section>
  </div>

</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { VInput, VSelect } from '../Form'
import CheckboxTable from '../CheckboxTable'

const {
  mapState: mapDataState,
} = createNamespacedHelpers('data')

export default {
  components: {
    VInput,
    VSelect,
    CheckboxTable
  },
  props: {
    index: {
      required: true,
      type: Number
    },
    form: {
      default: null
    },
    data: {
      default: {
        title: '',
        postnominal: '',
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        eveningEvent: null,
        package: null
      }
    },
    online: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      participant: {...this.data}
    }
  },
  computed: {
    ...mapDataState(['packages']),

    items () {
      let index = 0;
      let packages = this.packages.program.map(item => {
        index++;
        let checked = this.participant.package !== null && this.participant.package.id === item.id;
        return { nr: `0${index}`, checked, ...item }
      });
      return packages;
    },
    participantPackage: {
      get() {
        return this.participant.package ? [this.participant.package] : [];
      },
      set(val) {
        this.participant.package = val ? [...val].pop() : null;
      }
    },
  },
  methods: {
    error(field, returnMessage = false) {
      return this.form && this.form.errors.has(field)
        ? returnMessage && this.form.errors.get(field) || true
        : false
    },
  },
  watch: {
    participant: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<template>
  <div :class="groupClassList">
    <label class="radio">
      <input type="radio" v-model="internalValue" :name="name" :id="id" :value="id">
      <span class="checkbox-box"></span>
      <span class="checkbox-label text-regular">
        <slot></slot>
        <tooltip
          v-if="tooltip"
          trigger="hover"
          :options="{ placement: 'right-start' }">
          <div class="tooltip">
            <div class="tooltip-inner" v-html="tooltip"></div>
          </div>
          <span class="info-circle-icon" slot="reference"></span>
        </tooltip>
      </span>
    </label>
  </div>
</template>

<script>
import Tooltip from '../Tooltip'

export default {
  components: {
    Tooltip
  },

  props: {
    value: {
      default: ''
    },
    id: {
      required: true,
      type: [String]
    },
    name: {
      required: true,
      type: [String]
    },
    label: {
      default: null,
      type: [String]
    },
    tooltip: {
      default: null,
      type: [String]
    },
  },

  data () {
    return {
      lazyValue: this.value
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },
    groupClassList () {
      return {
        'form-group': true,
        'checkbox-group': true,
        'has-error': false,
        'is-filled': this.lazyValue && this.lazyValue.length
      }
    }
  },

  watch: {
    value (val) {
      this.lazyValue = val
    }
  },
}
</script>
